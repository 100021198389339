import { NestedKeyOf } from '../interfaces/types';
import { get } from 'lodash';

export type MatchType = 'startsWith' | 'endsWith' | 'includes';

export class FilterUtil {
    static filter<T>(values: T[], filterValue: string, matchType: MatchType = 'startsWith', paths?: NestedKeyOf<T>[]): T[] {
        return values?.filter(value => {
            if (!filterValue) {
                return true;
            }

            const isComplexValue = typeof value !== 'string' && Object.keys(value).length;
            let include: boolean;

            if (isComplexValue) {
                include = this.matchComplex(value, filterValue, matchType, paths);
            } else {
                include = this.matchSimple(value, filterValue, matchType);
            }

            return include;
        });
    }

    static matchSimple(value: any, filterValue: any, matchType: MatchType = 'startsWith'): boolean {
        return value?.toString().toLowerCase()[matchType](filterValue?.toLowerCase());
    }

    static matchComplex<T>(value: T, filterValue: string, matchType: MatchType = 'startsWith', paths?: NestedKeyOf<T>[]): boolean {
        // If no paths are specified then we check all keys for a match
        const keys = paths || Object.keys(value) as string[];

        return keys.some(key => {
            // Example: addresses.line1 -> addresses
            const arrayKey = key.split('.')[0] as string;
            const isArray = Array.isArray(value[arrayKey]);

            if (isArray) {
                const arrayItemPath = key.split('.').slice(1);
                // Find matches for each object in the array
                return value[arrayKey].some((item, i) => {
                    // Example: addresses[0].line1
                    const arrayItemValue = get(value, `${arrayKey}[${i}].${arrayItemPath}`);
                    // Check each item property value against the filter
                    return arrayItemValue?.toString().toLowerCase()[matchType](filterValue?.toLowerCase());
                });
            }

            // Support nested paths when getting values
            const pathValue = get(value, key);
            // Check at least one property includes the filter value
            return pathValue?.toString().toLowerCase()[matchType](filterValue?.toLowerCase());
        });
    }
}

