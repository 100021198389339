export namespace AppConfigurationActions {
    export class LoadPreferences {
        static readonly type = '[App Configuration] Load Preferences';
        constructor(public applicationId: string) {
        }
    }

    export class SavePreference {
        static readonly type = '[App Configuration] Save Preference';
        constructor(public applicationId: string, public preferenceId: string, public value: any, public preferenceType?: 'INTEGER' | 'DOUBLE' | 'STRING' | 'BOOLEAN') {
        }
    }
}
