import { Action, Selector, State, StateContext, StateToken } from '@ngxs/store';
import { PagedList, Paging, PagingHistory } from '../interfaces/paging.interface';
import {
    AbstractTrans,
    TenderTypeCode,
    TransStatusCode,
    TransSummary,
    TransTypeCode
} from '../interfaces/transaction.interface';
import { ElectronicJournalService } from '../services/electronic-journal.service';
import { ElectronicJournalActions } from './electronic-journal.actions';
import { Observable, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import {
    ElectronicJournalDetails,
    GetElectronicJournalByIdResponse,
    SearchElectronicJournalFilter,
    SearchElectronicJournalRequest
} from '../interfaces/electronic-journal.interface';
import { IBusinessUnit } from '../interfaces/business-unit.interface';
import { FormControlStatus, ValidationErrors } from '@angular/forms';
import { DirtyPropertySet, ObjectUtil } from '../utils/object.util';

export interface ElectronicJournalSearchFilterForm {
    model: SearchElectronicJournalFilter;
    dirty: boolean;
    status: FormControlStatus;
    errors: ValidationErrors;
}

export interface ElectronicJournalStateModel {
    searchResults: PagedList<TransSummary>;
    searchFilterForm: ElectronicJournalSearchFilterForm;
    committedSearchFilter: SearchElectronicJournalFilter;
    searchPaging: Paging;
    searchPagingHistory: PagingHistory;
    searchLoading: boolean;
    editingFilter: boolean;
    businessUnits: IBusinessUnit[];
    deviceIds: string[];
    transStatusCodes: TransStatusCode[];
    transTypeCodes: TransTypeCode[];
    tenderTypeCodes: TenderTypeCode[];
    details: ElectronicJournalDetails;
}

export const ELECTRONIC_JOURNAL_STATE_TOKEN =
    new StateToken<ElectronicJournalStateModel>('electronicJournal');

export const ELECTRONIC_JOURNAL_STATE_SEARCH_FILTER_FORM_PATH =
    `${ELECTRONIC_JOURNAL_STATE_TOKEN.getName()}.searchFilterForm`;

const DEFAULT_PAGING_LIMIT = 25;

@State<ElectronicJournalStateModel>({
    name: ELECTRONIC_JOURNAL_STATE_TOKEN,
    defaults: {
        searchResults: {
            items: [],
            totalCount: 0
        },
        searchFilterForm: {
            model: undefined,
            dirty: false,
            status: 'VALID',
            errors: {}
        },
        committedSearchFilter: undefined,
        searchPaging: {
            limit: DEFAULT_PAGING_LIMIT,
            fromValues: []
        },
        searchPagingHistory: {
            // We need a stack of the "fromValues" from previous pages to support paging backwards
            fromValuesStack: []
        },
        searchLoading: false,
        editingFilter: false,
        businessUnits: [],
        deviceIds: [],
        transTypeCodes: [],
        transStatusCodes: [],
        tenderTypeCodes: [],
        details: {
            summary: undefined,
            businessUnit: undefined,
            retailTrans: undefined,
            linesByNumber: undefined,
            linesByRefNumber: undefined
        }
    }
})
@Injectable()
export class ElectronicJournalState {
    constructor(private electronicJournalService: ElectronicJournalService) {
    }

    @Selector()
    static searchResults(state: ElectronicJournalStateModel): PagedList<TransSummary> {
        return state.searchResults;
    }

    @Selector()
    static searchLoading(state: ElectronicJournalStateModel): boolean {
        return state.searchLoading;
    }

    @Selector()
    static searchPaging(state: ElectronicJournalStateModel): Paging {
        return state.searchPaging;
    }

    @Selector()
    static searchPagingHistory(state: ElectronicJournalStateModel): PagingHistory {
        return state.searchPagingHistory;
    }

    @Selector()
    static editingFilter(state: ElectronicJournalStateModel): boolean {
        return state.editingFilter;
    }

    @Selector()
    static searchFilterForm(state: ElectronicJournalStateModel): ElectronicJournalSearchFilterForm {
        return state.searchFilterForm;
    }

    @Selector()
    static searchFilterFormHasValues(state: ElectronicJournalStateModel): boolean {
        // NOTE: NGXS Form doesn't correctly modify the dirty state, even when values are identical
        //       to the original state. Also, from a usability perspective, it's not useful to only check if a new value
        //       is identical to the original value and is more useful to check for non-falsy values and non-empty arrays.
        return ObjectUtil.getNonEmptyValueKeys(state.searchFilterForm.model).length > 0;
    }

    @Selector()
    static searchFilterFormDirtyProperties(state: ElectronicJournalStateModel): DirtyPropertySet<SearchElectronicJournalFilter> {
        return ObjectUtil.getDirtyProperties(state.searchFilterForm.model, state.committedSearchFilter);
    }

    @Selector()
    static hasDirtySearchFilterFormProperties(state: ElectronicJournalStateModel): boolean {
        const dirtyProperties = this.searchFilterFormDirtyProperties(state);
        return Object.keys(dirtyProperties).length > 0;
    }

    @Selector()
    static businessUnits(state: ElectronicJournalStateModel): IBusinessUnit[] {
        return state.businessUnits;
    }

    @Selector()
    static selectedBusinessUnits(state: ElectronicJournalStateModel): IBusinessUnit[] {
        const selectedBusinessUnitIds = state.searchFilterForm.model?.businessUnitIds;
        return state.businessUnits
            .filter(businessUnit => selectedBusinessUnitIds?.indexOf(businessUnit.businessUnitId) >= 0);
    }

    @Selector()
    static deviceIds(state: ElectronicJournalStateModel): string[] {
        return state.deviceIds;
    }

    @Selector()
    static selectedDeviceIds(state: ElectronicJournalStateModel): string[] {
        const selectedDeviceIds = state.searchFilterForm.model?.deviceIds;
        return state.deviceIds
            .filter(deviceId => selectedDeviceIds?.indexOf(deviceId) >= 0);
    }

    @Selector()
    static transStatusCodes(state: ElectronicJournalStateModel): TransStatusCode[] {
        return state.transStatusCodes;
    }

    @Selector()
    static selectedTransStatusCodes(state: ElectronicJournalStateModel): TransStatusCode[] {
        const selectedTransStatusCodes = state.searchFilterForm.model?.transStatusCodes;
        return state.transStatusCodes
            .filter(transStatusCode => selectedTransStatusCodes?.indexOf(transStatusCode) >= 0);
    }

    @Selector()
    static transTypeCodes(state: ElectronicJournalStateModel): TransTypeCode[] {
        return state.transTypeCodes;
    }

    @Selector()
    static selectedTransTypeCodes(state: ElectronicJournalStateModel): TransTypeCode[] {
        const selectedTransTypeCodes = state.searchFilterForm.model?.transTypeCodes;
        return state.transTypeCodes
            .filter(transTypeCode => selectedTransTypeCodes?.indexOf(transTypeCode) >= 0);
    }

    @Selector()
    static tenderTypeCodes(state: ElectronicJournalStateModel): TenderTypeCode[] {
        return state.tenderTypeCodes;
    }

    @Selector()
    static selectedTenderTypeCodes(state: ElectronicJournalStateModel): TenderTypeCode[] {
        const selectedTenderTypeCodes = state.searchFilterForm.model?.tenderTypeCodes;
        return state.tenderTypeCodes
            .filter(tenderTypeCode => selectedTenderTypeCodes?.indexOf(tenderTypeCode) >= 0);
    }

    @Selector()
    static details(state: ElectronicJournalStateModel): ElectronicJournalDetails {
        return state.details;
    }

    @Action(ElectronicJournalActions.GetById)
    getById(ctx: StateContext<ElectronicJournalStateModel>, action: ElectronicJournalActions.GetById): Observable<any> {
        return this.electronicJournalService.getById(
            action.businessUnitId,
            action.deviceId,
            action.businessDate,
            action.sequenceNumber
        ).pipe(
            // The `tap` argument is of type `any`, despite the `getById` method returning
            // type `Observable<GetElectronicJournalByIdResponse>, so we add the type here
            // to get type-checking
            tap((response: GetElectronicJournalByIdResponse) => {
                const linesMap = this.getLinesMap(response.retailTrans);
                ctx.patchState({
                    details: {
                        ...response,
                        linesByNumber: linesMap.byNumber,
                        linesByRefNumber: linesMap.byRefNumber
                    }
                });
            })
        );
    }

    @Action(ElectronicJournalActions.Search)
    search(ctx: StateContext<ElectronicJournalStateModel>): Observable<PagedList<TransSummary>> {
        const state = ctx.getState();
        const request: SearchElectronicJournalRequest = {
            ...state.committedSearchFilter,
            paging: state.searchPaging
        };

        ctx.patchState({
            searchLoading: true
        });

        return this.electronicJournalService.search(request)
            .pipe(
                map(response => response.results),
                tap(results => {
                    ctx.patchState({
                        searchResults: results,
                        searchLoading: false
                    });
                }),
                catchError(error => {
                    ctx.patchState({ searchLoading: false });
                    return throwError(error);
                })
            );
    }

    @Action(ElectronicJournalActions.SetSearchPaging)
    setSearchPaging(ctx: StateContext<ElectronicJournalStateModel>,
                    action: ElectronicJournalActions.SetSearchPaging) {
        const state = ctx.getState();

        ctx.patchState({
            searchPaging: {
                limit: action.paging?.limit || state.searchPaging.limit,
                fromValues: action.paging?.fromValues || state.searchPaging.fromValues
            }
        });
    }

    @Action(ElectronicJournalActions.ResetSearchPaging)
    resetSearchPaging(ctx: StateContext<ElectronicJournalStateModel>) {
        const state = ctx.getState();

        ctx.patchState({
            searchPaging: {
                limit: state.searchPaging.limit || DEFAULT_PAGING_LIMIT,
                fromValues: []
            },
            searchPagingHistory: {
                fromValuesStack: []
            }
        });
    }

    @Action(ElectronicJournalActions.NextSearchPage)
    nextSearchPage(ctx: StateContext<ElectronicJournalStateModel>,
                   action: ElectronicJournalActions.NextSearchPage) {

        const state = ctx.getState();
        const fromValuesStack = [...state.searchPagingHistory.fromValuesStack];
        fromValuesStack.push(action.paging.fromValues);

        ctx.patchState({
            searchPaging: {
                limit: action.paging.limit || state.searchPaging.limit,
                fromValues: action.paging?.fromValues
            },
            searchPagingHistory: {
                fromValuesStack
            }
        });
    }

    @Action(ElectronicJournalActions.PreviousSearchPage)
    previousSearchPage(ctx: StateContext<ElectronicJournalStateModel>,
                       action: ElectronicJournalActions.PreviousSearchPage) {

        const state = ctx.getState();
        const fromValuesStack = [...state.searchPagingHistory.fromValuesStack];
        fromValuesStack.pop();

        ctx.patchState({
            searchPaging: {
                limit: action.paging.limit || state.searchPaging.limit,
                fromValues: action.paging?.fromValues
            },
            searchPagingHistory: {
                fromValuesStack
            }
        });
    }

    @Action(ElectronicJournalActions.SetEditingFilter)
    setEditingFilter(ctx: StateContext<ElectronicJournalStateModel>,
                     action: ElectronicJournalActions.SetEditingFilter) {
        ctx.patchState({
            editingFilter: action.editing
        });
    }

    @Action(ElectronicJournalActions.GetBusinessUnits)
    getBusinessUnits(ctx: StateContext<ElectronicJournalStateModel>): Observable<IBusinessUnit[]> {
        return this.electronicJournalService.getBusinessUnits()
            .pipe(
                map(response => response.businessUnits),
                tap(businessUnits => {
                    ctx.patchState({
                        businessUnits
                    });
                })
            );
    }

    @Action(ElectronicJournalActions.GetDevices)
    getDevices(ctx: StateContext<ElectronicJournalStateModel>): Observable<string[]> {
        return this.electronicJournalService.getDevices()
            .pipe(
                map(response => response.deviceIds),
                tap(deviceIds => {
                    ctx.patchState({
                        deviceIds
                    });
                })
            );
    }

    @Action(ElectronicJournalActions.GetTransTypeCodes)
    getTransTypeCodes(ctx: StateContext<ElectronicJournalStateModel>): Observable<TransTypeCode[]> {
        return this.electronicJournalService.getTransTypeCodes()
            .pipe(
                map(response => response.transTypeCodes),
                tap(transTypeCodes => {
                    ctx.patchState({
                        transTypeCodes
                    });
                })
            );
    }

    @Action(ElectronicJournalActions.GetTransStatusCodes)
    getTransStatusCodes(ctx: StateContext<ElectronicJournalStateModel>): Observable<TransStatusCode[]> {
        return this.electronicJournalService.getTransStatusCodes()
            .pipe(
                map(response => response.transStatusCodes),
                tap(transStatusCodes => {
                    ctx.patchState({
                        transStatusCodes
                    });
                })
            );
    }

    @Action(ElectronicJournalActions.GetTenderTypeCodes)
    getTenderTypeCodes(ctx: StateContext<ElectronicJournalStateModel>): Observable<TenderTypeCode[]> {
        return this.electronicJournalService.getTenderTypeCodes()
            .pipe(
                map(response => response.tenderTypeCodes),
                tap(tenderTypeCodes => {
                    ctx.patchState({
                        tenderTypeCodes
                    });
                })
            );
    }

    @Action(ElectronicJournalActions.CommitSearchFilterForm)
    commitSearchFilterForm(ctx: StateContext<ElectronicJournalStateModel>): void {
        ctx.patchState({
            committedSearchFilter: ctx.getState().searchFilterForm.model
        });
    }

    @Action(ElectronicJournalActions.ResetCommittedSearchFilterForm)
    resetCommittedSearchFilterForm(ctx: StateContext<ElectronicJournalStateModel>): void {
        ctx.patchState({
            committedSearchFilter: undefined
        });
    }

    getLinesMap(trans: AbstractTrans) {
        const byNumberMap: Record<number, any> = {};
        const byRefNumberMap: Record<number, any> = {};
        let allLines = [];

        if (trans?.allPromoCodeLineItems) {
            allLines = allLines.concat(trans?.allPromoCodeLineItems);
        }

        if (trans?.allRetailLineItems) {
            allLines = allLines.concat(trans?.allRetailLineItems);
        }

        if (trans?.allTaxGroupLineItems) {
            allLines = allLines.concat(trans?.allTaxGroupLineItems);
        }

        if (trans?.authLineItems) {
            allLines = allLines.concat(trans?.authLineItems);
        }

        if (trans?.cardLineItems) {
            allLines = allLines.concat(trans?.cardLineItems);
        }

        if (trans?.tenderLineItems) {
            allLines = allLines.concat(trans?.tenderLineItems);
        }

        for (let i = 0; i < allLines.length; i++) {
            const line = allLines[i];
            byNumberMap[allLines[i].lineSequenceNumber] = line;

            if (line.refLineSequenceNumber !== undefined) {
                byRefNumberMap[line.refLineSequenceNumber] = line;
            }
        }

        return {
            byNumber: byNumberMap,
            byRefNumber: byRefNumberMap
        };
    }
}
