import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { FormsService } from '../../forms.service';

@Component({
    selector: 'jmcc-input',
    templateUrl: './input.component.html',
    styleUrls: ['./input.component.scss']
})
export class InputComponent implements OnInit {

    @Input()
    id: string;

    @Input()
    label: string;

    @Input()
    textArea: boolean;

    @Input()
    control: FormControl = new FormControl('');

    @Input()
    placeholder: string;

    @Input()
    errorMessages: Map<string, string> = new Map<string, string>();

    @Input()
    required: boolean;

    @Input()
    disabled: boolean;

    @Input()
    autoCompleteOptions: string[];

    @Input()
    browserAutoComplete: string;

    @Input()
    tooltip: string;

    @Input()
    tooltipPosition: 'top' | 'right' | 'left' | 'bottom' = 'top';

    @Input()
    size: 'tiny' | 'small' | 'medium' | 'large' = 'large';

    @Input()
    textAreaInitialHeight: 'tiny' | 'small' | 'medium' | 'large';

    @Input()
    type = 'text';

    @Input()
    hint: string;

    filteredOptions$: Observable<string[]>;

    constructor(private formsService: FormsService, private cdRef: ChangeDetectorRef) {
    }

    ngOnInit(): void {
        this.errorMessages = this.formsService.generateErrorMessages(this.errorMessages, this.label);

        if (this.autoCompleteOptions) {
            this.filteredOptions$ = of(this.autoCompleteOptions);
        }
        this.cdRef.detectChanges();
    }

    getStatus(): string {
        return this.formsService.getStatus(this.control);
    }

    getErrorMessage(): string {
        return this.formsService.getErrorMessage(this.control, this.errorMessages);
    }

    getBrowserAutocomplete(): string {
        if (this.browserAutoComplete) {
            return this.browserAutoComplete;
        } else if (this.autoCompleteOptions) {
            return 'off';
        }
        return 'on';
    }

    onInputChange(): void {
        if (this.type === 'text') {
            this.filteredOptions$ = this.getFilteredOptions(this.control.value);
        }
    }

    onSelect(value: string): void {
        if (value) {
            this.filteredOptions$ = this.getFilteredOptions(value);
        }
    }

    getFilteredOptions(value: string): Observable<string[]> {
        return of(value).pipe(
            map(filterString => this.filter(filterString))
        );
    }

    private filter(value: string): string[] {
        if (this.type === 'text') {
            const filterValue = value?.toLowerCase();
            return this.autoCompleteOptions?.filter(optionValue => optionValue?.toLowerCase().includes(filterValue)) || [];
        }
        return [];
    }

}
