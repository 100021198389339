import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { GetVersionedBusinessUnitsResponse } from '../interfaces/deployments/deployment-api.interface';
import {
    IDeployment,
    IDeploymentTemplate,
    IInstallation,
    IInstallationGroup
} from '../interfaces/deployments/deployment.interface';

@Injectable()
export class DeploymentsService {

    readonly deploymentsServicePrefix = 'app/deployments';

    constructor(private http: HttpClient) {
    }

    getAvailableVersions(packageName: string): Observable<string[]> {
        return this.http.get<string[]>(`${this.deploymentsServicePrefix}/versions/${packageName}/all`);
    }

    getPackages(): Observable<string[]> {
        return this.http.get<string[]>(`${this.deploymentsServicePrefix}/packages/all`);
    }

    getVersionedBusinessUnits(packageName: string): Observable<GetVersionedBusinessUnitsResponse> {
        return this.http.get<GetVersionedBusinessUnitsResponse>(`${this.deploymentsServicePrefix}/businessUnits/${packageName}`);
    }

    getAllDeployments(packageName: string): Observable<IDeployment[]> {
        return this.http.get<IDeployment[]>(`${this.deploymentsServicePrefix}/deployments/${packageName}/all`);
    }

    getAllDeploymentTemplates(): Observable<IDeploymentTemplate[]> {
        return this.http.get<IDeploymentTemplate[]>(`${this.deploymentsServicePrefix}/templates/all`);
    }

    getInstallationGroups(): Observable<IInstallationGroup[]> {
        return this.http.get<IInstallationGroup[]>(`${this.deploymentsServicePrefix}/groups`);
    }

    saveInstallationGroups(installationGroup: IInstallationGroup): Observable<void> {
        return this.http.post<void>(`${this.deploymentsServicePrefix}/group/save`, { installationGroup: installationGroup });
    }

    getInstallationsByGroup(groupId: string, packageName: string): Observable<IInstallation[]> {
        return this.http.get<IInstallation[]>(`${this.deploymentsServicePrefix}/group/${groupId}/installations/${packageName}`);
    }

    saveDeploymentTemplate(deploymentTemplate: IDeploymentTemplate): Observable<void> {
        return this.http.post<void>(`${this.deploymentsServicePrefix}/templates/save`, { template: deploymentTemplate });
    }

    saveDeployment(deployment: IDeployment, packageName: string): Observable<any> {
        return this.http.post<any>(`${this.deploymentsServicePrefix}/deployments/save`, {
            deployment:
            {
                version: deployment.version,
                deploymentId: deployment.deploymentId,
                deploymentName: deployment.deploymentName,
                templateId: deployment.template.templateId,
                description: deployment.description,
                packageName: packageName,
                color: deployment.color,
                // Strip timezone
                activationTime: deployment.activationTime.format('YYYY-MM-DDTHH:mm:ss'),
                deploymentStatusCode: deployment.deploymentStatusCode,
                currentStepIndex: deployment.currentStepIndex,
            }
        });
    }

    cancelDeployment(deploymentId: string): Observable<any> {
        return this.http.post<any>(`${this.deploymentsServicePrefix}/deployments/cancel`, { deploymentId });
    }

    manuallyAdvance(deploymentId: string): Observable<any> {
        return this.http.post<any>(`${this.deploymentsServicePrefix}/deployments/advanceManually`, { deploymentId });
    }

}
