import { IAttributeConfig } from '../interfaces/promotion/promotion-attribute.interface';

export namespace PromotionAttributeConfigurationActions {
    const typePrefix = '[Promotion Attribute Configuration] ';
    export class GetAllAttributeConfigs {
        static readonly type = typePrefix + 'Get All Attribute Configs';

        constructor(public forceReload: boolean) { }
    }

    export class SearchAttributeConfigs {
        static readonly type = typePrefix + 'Search Attribute Configs';

        constructor(public searchString: string) { }
    }

    export class ClearSearch {
        static readonly type = typePrefix + 'Clear Search';
    }

    export class SaveAttributeConfig {
        static readonly type = typePrefix + 'Save Attribute Config';

        constructor(public attribute: IAttributeConfig) { }
    }

    export class DeleteAttributeConfig {
        static readonly type = typePrefix + 'Delete Attribute Config';

        constructor(public attribute: IAttributeConfig) { }
    }

    export class LoadAttributeConfigIntoForm {
        static readonly type = typePrefix + 'Load Attribute Config Into Form';

        constructor(public attributeId: string) { }
    }

    export class ClearForm {
        static readonly type = typePrefix + 'Clear Form';
    }
}
