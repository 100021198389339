import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
    GetConfigEditGroupsResponse,
    GetConfigMetadataResponse,
    SaveConfigEditGroupResponse
} from '../interfaces/config-management/config-management-api.interface';
import {
    IConfigEditGroup,
    IDeviceConfiguration,
    IPaymentTerminalConfiguration,
    IPrinterConfiguration,
    NewConfigSession
} from '../interfaces/config-management/config-management.interface';
import { ICountry } from '../interfaces/country.interface';
import { IState } from '../interfaces/state.interface';
import { IBusinessUnit } from '../interfaces/business-unit.interface';
import { Device } from '../interfaces/device.interface';

@Injectable()
export class ConfigManagementService {

    readonly configManagementPrefix = 'app/config-mgmt';

    constructor(private http: HttpClient) {
    }

    getConfigMetadata(includeConfiguration?: boolean): Observable<GetConfigMetadataResponse> {
        return this.http.put<GetConfigMetadataResponse>(`${this.configManagementPrefix}/getConfigMetaData`, { includeConfiguration });
    }

    getDeviceConfigurations(businessUnitId: string): Observable<IDeviceConfiguration[]> {
        return this.http.get<IDeviceConfiguration[]>(`${this.configManagementPrefix}/${businessUnitId}/getDeviceConfigurations`);
    }

    getPrinterConfigurations(businessUnitId: string): Observable<IPrinterConfiguration[]> {
        return this.http.get<IPrinterConfiguration[]>(`${this.configManagementPrefix}/${businessUnitId}/getPrinterConfigurations`);
    }

    deleteDeviceConfiguration(deviceId: string, deviceName: string): Observable<void> {
        return this.http.post<void>(`${this.configManagementPrefix}/deleteDeviceConfiguration`, {
            deviceId,
            deviceName
        });
    }

    deletePrinterConfiguration(businessUnitId: string, printerId: string): Observable<void> {
        return this.http.post<void>(`${this.configManagementPrefix}/deletePrinterConfiguration`, {
            businessUnitId,
            printerId
        });
    }

    deletePaymentTerminalConfiguration(businessUnitId: string, paymentDeviceId: string): Observable<void> {
        return this.http.post<void>(`${this.configManagementPrefix}/deletePaymentTerminalConfiguration`, {
            businessUnitId,
            paymentDeviceId
        });
    }

    saveDevices(devices: IDeviceConfiguration[], printers: IPrinterConfiguration[], paymentTerminals: IPaymentTerminalConfiguration[]): Observable<void> {
        return this.http.put<void>(`${this.configManagementPrefix}/saveDevices`, {
            devices,
            printers,
            paymentTerminals
        });
    }

    getPrinterTypes(): Observable<string[]> {
        return this.http.get<string[]>(`${this.configManagementPrefix}/getPrinterTypes`);
    }

    getPaymentTerminalConfigurations(businessUnitId: string): Observable<IPaymentTerminalConfiguration[]> {
        return this.http.get<IPaymentTerminalConfiguration[]>(`${this.configManagementPrefix}/${businessUnitId}/getPaymentTerminalConfigurations`);
    }

    getConfigEditGroups(attachDetails = true): Observable<GetConfigEditGroupsResponse> {
        return this.http.put<GetConfigEditGroupsResponse>(`${this.configManagementPrefix}/getConfigEditGroups`, { attachDetails });
    }

    getCountries(): Observable<ICountry[]> {
        return this.http.get<ICountry[]>(`${this.configManagementPrefix}/countries`);
    }

    getStates(): Observable<IState[]> {
        return this.http.get<IState[]>(`${this.configManagementPrefix}/states`);
    }

    getBusinessUnits(): Observable<IBusinessUnit[]> {
        return this.http.get<IBusinessUnit[]>(`${this.configManagementPrefix}/business-units`);
    }

    getDevices(): Observable<Device[]> {
        return this.http.get<Device[]>(`${this.configManagementPrefix}/devices`);
    }

    getDeviceTypes(): Observable<string[]> {
        return this.http.get<any[]>(`${this.configManagementPrefix}/device-types`);
    }

    getTillTypes(): Observable<string[]> {
        return this.http.get<any[]>(`${this.configManagementPrefix}/till-types`);
    }

    getLocales(): Observable<string[]> {
        return this.http.get<any[]>(`${this.configManagementPrefix}/supportedLocales`);
    }

    getAppIds(): Observable<string[]> {
        return this.http.get<string[]>(`${this.configManagementPrefix}/getAppIds`);
    }

    getDeviceAppIds(): Observable<string[]> {
        return this.http.get<string[]>(`${this.configManagementPrefix}/getDeviceAppIds`);
    }

    startNewSession(newConfigSession: NewConfigSession): Observable<SaveConfigEditGroupResponse> {
        return this.http.post<SaveConfigEditGroupResponse>(`${this.configManagementPrefix}/startNewSession`,
            {
                editGroupName: newConfigSession.editGroupName,
                tags: newConfigSession.tags,
                effectiveStartTime: newConfigSession?.effectiveStartTime?.format('YYYY-MM-DDTHH:mm:ss')
            });
    }

    cancelConfigSession(editGroupId: string): Observable<SaveConfigEditGroupResponse> {
        return this.http.put<SaveConfigEditGroupResponse>(`${this.configManagementPrefix}/cancelSession`, {
            editGroupId
        });
    }

    saveConfigEditGroup(configEditGroup: IConfigEditGroup): Observable<SaveConfigEditGroupResponse> {
        return this.http.put<SaveConfigEditGroupResponse>(`${this.configManagementPrefix}/saveConfigEditGroup`, {
            group: configEditGroup
        });
    }

    removeConfigEditGroup(editGroupId: string): Observable<any> {
        return this.http.delete(`${this.configManagementPrefix}/removeConfigEditGroup/${editGroupId}`);
    }

    getUUID(): Observable<any> {
        return this.http.get(`${this.configManagementPrefix}/uuid`);
    }
}
