import { Pipe, PipeTransform } from '@angular/core';
import { KeyValue } from '@angular/common';
import { IConfigTag } from '../../@core/interfaces/config-management/config-management.interface';
import { ConfigUtil } from '../../@core/utils/config.util';

@Pipe({ name: 'tagsWithValues' })
export class TagsWithValuesPipe implements PipeTransform {
    transform<T extends IConfigTag | KeyValue<string, string>>(tags: T[]): T[] {
        return tags?.filter(tag => {
            const configTag = tag as IConfigTag;
            const keyValueTag = tag as KeyValue<string, string>;

            const tagValue = configTag.tagValue !== undefined ? configTag.tagValue : keyValueTag.value;
            return !ConfigUtil.isEmptyOrAny(tagValue);
        });
    }
}
