import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';
import { LocalizedDurationUnitDisplay, LocalizedDurationUtil } from '../../@core/utils/localized-duration.util';

@Pipe({ name: 'localizedDuration' })
export class LocalizedDurationPipe implements PipeTransform {
    constructor(@Inject(LOCALE_ID) private localeId: string) {
    }

    transform(value: number, unitDisplay?: LocalizedDurationUnitDisplay): string {
        return LocalizedDurationUtil.format(this.localeId, value, unitDisplay);
    }
}
