import { ILabel } from '../interfaces/label.interface';

export namespace LabelsActions {

    export class GetPromotionLabels {
        static readonly type = '[Labels] Get all promotion type labels';
    }

    export class GetCampaignLabels {
        static readonly type = '[Labels] Get all campaign type labels';
    }

    export class ApplyCampaignLabels {
        static readonly type = '[Labels] Apply Campaign Labels';
        constructor(public objectId: string, public labelsToLink: string[], public labelsToUnlink: string[]) { }
    }

    export class CreateLabel {
        static readonly type = '[Labels] Create Label';
        constructor(public labelId: string, public objectType: string, public labelName: string, public color: string, public description: string) { }
    }

    export class DeleteLabel {
        static readonly type = '[Labels] Delete Label';

        constructor(public label: ILabel) {
        }
    }
}
