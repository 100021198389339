import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BusinessUnitGroupResponse, IBusinessUnitGroup } from '../interfaces/business-unit-group.interface';
import { GetBrandsByBusinessUnitIdsResponse, IBusinessUnit } from '../interfaces/business-unit.interface';
import { Observable } from 'rxjs';
import { ICountry } from '../interfaces/country.interface';
import { GetAllBrandsResponse } from '../interfaces/brand.interface';

@Injectable()
export class BusinessUnitService {

    readonly businessUnitServicePrefix = 'app/businessUnits/';

    constructor(private http: HttpClient) {
    }

    getBusinessUnitGroups(): Observable<{ businessUnitGroups: IBusinessUnitGroup[] }> {
        return this.http.get<{ businessUnitGroups: IBusinessUnitGroup[] }>(this.businessUnitServicePrefix + 'groups');
    }

    getBusinessUnits(): Observable<{ businessUnits: IBusinessUnit[] }> {
        return this.http.get<{ businessUnits: IBusinessUnit[] }>(this.businessUnitServicePrefix + 'all');
    }

    getBrandsByBusinessUnitIds(businessUnitIds: string[]): Observable<GetBrandsByBusinessUnitIdsResponse> {
        return this.http.put<GetBrandsByBusinessUnitIdsResponse>(this.businessUnitServicePrefix + 'brandsByBusinessUnitIds', { businessUnitIds });
    }

    saveBusinessUnit(businessUnit: IBusinessUnit): Observable<void> {
        return this.http.put<void>(this.businessUnitServicePrefix + 'save', { businessUnit, updateAddresses: true });
    }

    deleteBusinessUnit(businessUnitId: string): Observable<void> {
        return this.http.put<void>(this.businessUnitServicePrefix + 'delete', { businessUnitId });
    }

    getCountries(): Observable<{ countries: ICountry[] }> {
        return this.http.get<{ countries: ICountry[] }>(this.businessUnitServicePrefix + 'countries');
    }

    createBusinessUnitGroup(businessUnitGroupId: string, businessUnitGroupName: string, businessUnits: IBusinessUnit[]): Observable<BusinessUnitGroupResponse> {
        return this.http.put<BusinessUnitGroupResponse>(this.businessUnitServicePrefix + 'groups/create', { businessUnitGroupId, businessUnitGroupName, businessUnits });
    }

    deleteBusinessUnitGroup(businessUnitGroupId: string): Observable<BusinessUnitGroupResponse> {
        return this.http.put<BusinessUnitGroupResponse>(this.businessUnitServicePrefix + 'groups/delete', { businessUnitGroupId });
    }

    updateBusinessUnitGroup(businessUnitGroupId: string, businessUnitGroupName: string, businessUnits: IBusinessUnit[]): Observable<BusinessUnitGroupResponse> {
        return this.http.put<BusinessUnitGroupResponse>(this.businessUnitServicePrefix + 'groups/update', { businessUnitGroupId, businessUnitGroupName, businessUnits });
    }

    getAllBrands(): Observable<GetAllBrandsResponse> {
        return this.http.get<GetAllBrandsResponse>(this.businessUnitServicePrefix + 'brands/all');
    }

}
