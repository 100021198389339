<button class="icon-button" nbButton type="button"
        [ghost]="ghostButton"
        [shape]="shape"
        [disabled]="disabled"
        [nbTooltip]="tooltip"
        [nbTooltipPlacement]="tooltipPosition"
        nbTooltipStatus="primary"
        [size]="size"
        (click)="onClick.emit($event)">
    <nb-icon class="icon-button-icon" [icon]="icon" [pack]="iconPack"></nb-icon>
</button>
