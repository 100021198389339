import { Pipe, PipeTransform } from '@angular/core';

/**
 * Retrieves the revision number from the full promotionId, if present
 * @example 'CAMP-12-3' -> '3'
 * @example 'CAMP-12' -> ''
 * @example undefined -> ''
 */
@Pipe({
    name: 'promotionRevision',
})
export class PromotionRevisionPipe implements PipeTransform {
    transform(promotionId: string): string {
        return promotionId?.split('-')[2] ?? '';
    }
}
