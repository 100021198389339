import { IItem } from './item.interface';
import { IQualificationItemAttribute, IRewardItemAttribute } from './promotion/promotion.interface';
import { FormControl } from '@angular/forms';
import { IUploadedFile } from './promotion/uploaded-file.interface';
import { IOfferTemplateItemGroupLink } from './promotion/offer-template-item-group-link.interface';
import { Moment } from 'moment/moment';

export interface IItemGroup {
    id: string;
    name?: string;
    attributes?: IQualificationItemAttribute[] | IRewardItemAttribute[];
}

export type ItemOrGroup = IItem | IItemGroup;

export interface IServerItemGroup {
    categoryType: string;
    itemGroupId: string;
    description: string;
    parentGroupId: string;
    useAsClassifier: boolean;
    levelId: string;
}

export interface IStagedItemGroup {
    stagedItemGroupId: string;
    stagedItemGroupName: string;
    description: string;
    stagedItemGroupStatus?: string;
    includedItemIds: string[];
    includedItemGroupIds: string[];
    excludedItemIds: string[];
    excludedItemGroupIds: string[];
    effectiveStartTime?: string;
    effectiveEndTime?: string;
    offerTemplateItemGroupLinks?: IOfferTemplateItemGroupLink[];
}

export namespace StagedItemGroupStatusTypeCode {
    export const ACTIVE = 'ACTIVE';
    export const INACTIVE = 'INACTIVE';
}

export interface GenerateStagedItemGroupRequest {
    stagedItemGroupName: string;
    description: string;
    includedItemIds: string[];
    includedItemGroupIds: string[];
    excludedItemIds: string[];
    excludedItemGroupIds: string[];
}

export interface StagedItemGroupActionResponse {
    success: boolean;
    message?: string;
    stagedItemGroup?: IStagedItemGroup;
}

export interface StagedItemGroupFormModel {
    groupName: string;
    groupDescription: string;
    startDate: Moment;
    endDate: Moment;
    included: ItemOrGroup[];
    excluded: ItemOrGroup[];
}

export interface ItemSearchFormModel {
    itemId: FormControl<string>;
    groupId: FormControl<string>;
    itemImport: FormControl<IUploadedFile[]>;
    itemPaste: FormControl<string>;
}

export interface GetItemGroupsResponse {
    groups: IServerItemGroup[];
}

export interface GetStagedItemGroupsResponse {
    stagedItemGroups: IStagedItemGroup[];
}
