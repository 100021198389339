import { Injectable } from '@angular/core';
import {
    DeploymentStatusCode,
    IDeployment,
    IDeploymentError,
    IInstallation,
    IVersionedBusinessUnit
} from '../interfaces/deployments/deployment.interface';
import * as moment from 'moment';

export const MISSING_DATA_LABEL = 'Missing Data';
export const MIXED_VERSIONS_LABEL = 'Mixed Versions';
export const FAILED_INSTALLATION_LABEL = 'Install Failure';
export const OTHER_ERROR_LABEL = 'Other Error';

export const UNKNOWN_DEPLOYMENT: IDeployment = {
    deploymentId: undefined,
    deploymentName: 'Unknown Deployment',
    version: 'Unknown',
    color: 'basic',
    templateId: 'Unknown',
    activationTime: undefined,
    deploymentStatusCode: DeploymentStatusCode.UNKNOWN
};

@Injectable()
export class DeploymentsDataAggregatorService {

    constructor() {
    }

    sortVersionedUnitsByVersion(versionedBusinessUnits: IVersionedBusinessUnit[]) {
        const versionMap = new Map<string, IVersionedBusinessUnit[]>();
        versionedBusinessUnits.forEach(unit => {
            if (this.isMissingData(unit.installations)) {
                this.addToOrCreateUnitSeries(versionMap, unit, MISSING_DATA_LABEL);
            } else if (!this.isVersionConsistent(unit.installations)) {
                this.addToOrCreateUnitSeries(versionMap, unit, this.getDominantVersion(unit.installations));
            } else {
                this.addToOrCreateUnitSeries(versionMap, unit, this.getVersion(unit.installations));
            }
        });
        return versionMap;
    }

    sortInstallationsByVersion(installations: IInstallation[]) {
        const versionMap = new Map<string, IInstallation[]>();
        installations.forEach(install => {
            this.addToOrCreateInstallSeries(versionMap, install, MISSING_DATA_LABEL);
        });
        return versionMap;
    }


    buildErrors(versionedBusinessUnits: IVersionedBusinessUnit[], deployment?: IDeployment): IDeploymentError[] {
        let errors: IDeploymentError[] = [];
        versionedBusinessUnits.forEach(unit => {
            if (this.wasSuccessfulInstall(unit.installations, deployment?.version)) {
                errors.push(this.buildErrorsForUnit(unit, FAILED_INSTALLATION_LABEL));
            }
            if (!this.isVersionConsistent(unit.installations)) {
                errors.push(this.buildErrorsForUnit(unit, MIXED_VERSIONS_LABEL));
            }
        });
        if (deployment) {
            errors.forEach(error => {
                error.installationsAffected = error.installationsAffected.filter(install =>
                    moment(install.installTime)?.isAfter(moment(deployment.activationTime)));
            });
            errors = errors.filter(e => e.installationsAffected?.length > 0);
        }
        return errors;
    }

    getDominantVersion(installations: IInstallation[]) {
        const versionCounts: { [key: string]: number } = {};

        // Count occurrences of each version
        installations.forEach(installation => {
            const version = installation.version;
            if (versionCounts[version]) {
                versionCounts[version]++;
            } else {
                versionCounts[version] = 1;
            }
        });

        // Find the version with the highest count
        let dominantVersion: string;
        let maxCount = 0;
        for (const version in versionCounts) {
            if (versionCounts[version] > maxCount) {
                dominantVersion = version;
                maxCount = versionCounts[version];
            }
        }

        return dominantVersion;
    }

    private wasSuccessfulInstall(installations: IInstallation[], version?: string) {
        if (version?.length > 0) {
            return installations.filter(install => install.version === version).findIndex(e => !e.successfulInstall) >= 0;
        } else {
            return installations.findIndex(e => !e.successfulInstall) >= 0;
        }
    }

    private isVersionConsistent(installations: IInstallation[]) {
        return installations.every(e => e.version === installations[0].version);
    }

    private isMissingData(installations: IInstallation[]) {
        return !installations || !installations[0];
    }

    private getVersion(installations: IInstallation[]) {
        return installations[0].version;
    }

    private buildErrorsForUnit(unit: IVersionedBusinessUnit, errorLabel: string): IDeploymentError {
        if (errorLabel === MIXED_VERSIONS_LABEL) {
            return {
                unitAffected: unit.businessUnit,
                installationsAffected: unit.installations,
                label: MIXED_VERSIONS_LABEL
            };
        } else if (errorLabel === FAILED_INSTALLATION_LABEL) {
            return {
                unitAffected: unit.businessUnit,
                installationsAffected: unit.installations.filter(e => !e.successfulInstall),
                label: FAILED_INSTALLATION_LABEL
            };
        }
    }

    private addToOrCreateUnitSeries(versionMap: Map<string, IVersionedBusinessUnit[]>, unitToAdd: IVersionedBusinessUnit, label: string): void {
        if (versionMap.has(label)) {
            versionMap.get(label).push(unitToAdd);
        } else {
            versionMap.set(label, [unitToAdd]);
        }
    }

    private addToOrCreateInstallSeries(versionMap: Map<string, IInstallation[]>, installToAdd: IInstallation, label: string): void {
        if (versionMap.has(label)) {
            versionMap.get(label).push(installToAdd);
        } else {
            versionMap.set(label, [installToAdd]);
        }
    }
}
