import { INearMissMessage } from '../interfaces/near-miss-message.interface';

export namespace NearMissMessageActions {
    export class ClearForm {
        static readonly type = '[Near Miss Message] Clear Create Form';
    }

    export class GetAllNearMissMessages {
        static readonly type = '[Near Miss Message] All Near Miss Messages';
        constructor(public forceReload?: boolean) { }
    }

    export class GetNearMissMessage {
        static readonly type = '[Near Miss Message] Load Near Miss Message';
        constructor(public nearMissMessageId: string) { }
    }

    export class SaveNearMissMessage {
        static readonly type = '[Near Miss Message] Save Near Miss Message';
        constructor(public nearMissMessage: INearMissMessage) { }
    }

    export class DeleteNearMissMessage {
        static readonly type = '[Near Miss Message] Delete Near Miss Message';
        constructor(public nearMissMessageId: string) { }
    }
}
