import { Action, Selector, State, StateContext, StateToken, Store, createSelector } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { PromotionService } from '../services/promotion.service';
import { tap } from 'rxjs/operators';
import { AttributeFormModel, AttributeLevel, GetAllAttributeConfigsResponse, IAttributeConfig } from '../interfaces/promotion/promotion-attribute.interface';
import { Observable, of } from 'rxjs';
import { IGenericServerResponse } from '../interfaces/generic-server-response';
import { NgxsForm } from '../interfaces/ngxs-form.interface';
import { PromotionAttributeConfigurationActions } from './promotion-attribute-configuration.action';

export class PromotionAttributeConfigurationStateModel {
    attributes: IAttributeConfig[];
    searchString: string;
    attributeForm: NgxsForm<AttributeFormModel>;
}

const PROMOTION_ATTRIBUTE_CONFIGURATION_STATE_TOKEN = new StateToken<PromotionAttributeConfigurationStateModel>('promotionAttributeConfiguration');

@State<PromotionAttributeConfigurationStateModel>({
    name: PROMOTION_ATTRIBUTE_CONFIGURATION_STATE_TOKEN,
    defaults: {
        attributes: [],
        searchString: null,
        attributeForm: {
            model: undefined,
            dirty: false,
            status: '',
            errors: {}
        }
    }
})
@Injectable()
export class PromotionAttributeConfigurationState {

    constructor(private promotionService: PromotionService, private store: Store) {
    }

    static attributeById(attributeId: string) {
        return createSelector([PromotionAttributeConfigurationState], (state: PromotionAttributeConfigurationStateModel): IAttributeConfig =>
            state.attributes?.find(q => q.attributeId.toLowerCase() === attributeId.toLowerCase()));
    }

    private static shouldShowAttribute(state: PromotionAttributeConfigurationStateModel, attribute: IAttributeConfig): boolean {
        if (state.searchString) {
            return attribute.attributeId?.toLowerCase().includes(state.searchString) ||
                attribute.displayName?.toLowerCase().includes(state.searchString);
        }
        return true;
    }

    @Selector()
    static allAttributes(state: PromotionAttributeConfigurationStateModel): IAttributeConfig[] {
        return state.attributes?.filter(config => this.shouldShowAttribute(state, config));
    }

    @Selector()
    static transactionAttributes(state: PromotionAttributeConfigurationStateModel): IAttributeConfig[] {
        return state.attributes?.filter(config => this.shouldShowAttribute(state, config))
            .filter(config => config.attributeLevel === AttributeLevel.TRANS);
    }

    @Selector()
    static itemAttributes(state: PromotionAttributeConfigurationStateModel): IAttributeConfig[] {
        return state.attributes?.filter(config => this.shouldShowAttribute(state, config))
            .filter(config => config.attributeLevel === AttributeLevel.ITEM);
    }

    @Selector()
    static searchString(state: PromotionAttributeConfigurationStateModel): string {
        return state.searchString;
    }

    @Action(PromotionAttributeConfigurationActions.GetAllAttributeConfigs)
    getPromotionAttributeConfigs(ctx: StateContext<PromotionAttributeConfigurationStateModel>, action: PromotionAttributeConfigurationActions.GetAllAttributeConfigs): Observable<GetAllAttributeConfigsResponse> {
        if (action.forceReload || !ctx.getState().attributes?.length) {
            return this.promotionService.getAllAttributeConfigs().pipe(
                tap((result: GetAllAttributeConfigsResponse) => {
                    if (result.attributeConfigs) {
                        ctx.patchState({
                            attributes: result.attributeConfigs
                        });
                    }
                })
            );
        }
        return of({ attributeConfigs: ctx.getState().attributes });
    }

    @Action(PromotionAttributeConfigurationActions.SearchAttributeConfigs)
    searchAttributeConfigs(ctx: StateContext<PromotionAttributeConfigurationStateModel>, action: PromotionAttributeConfigurationActions.SearchAttributeConfigs): void {
        ctx.patchState({
            searchString: action.searchString?.toLowerCase()
        });
    }

    @Action(PromotionAttributeConfigurationActions.ClearSearch)
    clearSearch(ctx: StateContext<PromotionAttributeConfigurationStateModel>): void {
        ctx.patchState({
            searchString: null
        });
    }

    @Action(PromotionAttributeConfigurationActions.SaveAttributeConfig)
    savePromotionAttributeConfig(ctx: StateContext<PromotionAttributeConfigurationStateModel>, action: PromotionAttributeConfigurationActions.SaveAttributeConfig): Observable<IGenericServerResponse> {
        if (action.attribute) {
            return this.promotionService.saveAttributeConfig(action.attribute).pipe(
                tap((response: IGenericServerResponse) => {
                    if (response.success) {
                        this.store.dispatch(new PromotionAttributeConfigurationActions.GetAllAttributeConfigs(true));
                    } else {
                        throw new Error(response.message);
                    }
                })
            );
        }
    }

    @Action(PromotionAttributeConfigurationActions.DeleteAttributeConfig)
    deletePromotionAttributeConfig(ctx: StateContext<PromotionAttributeConfigurationStateModel>, action: PromotionAttributeConfigurationActions.DeleteAttributeConfig): Observable<IGenericServerResponse> {
        if (action.attribute?.attributeId) {
            return this.promotionService.deleteAttributeConfig(action.attribute.attributeId).pipe(
                tap((response: IGenericServerResponse) => {
                    if (response.success) {
                        this.store.dispatch(new PromotionAttributeConfigurationActions.GetAllAttributeConfigs(true));
                    } else {
                        throw new Error(response.message);
                    }
                })
            );
        }
    }

    @Action(PromotionAttributeConfigurationActions.LoadAttributeConfigIntoForm)
    loadAttributeConfigIntoForm(ctx: StateContext<PromotionAttributeConfigurationStateModel>, action: PromotionAttributeConfigurationActions.LoadAttributeConfigIntoForm): Observable<GetAllAttributeConfigsResponse> {
        return this.store.dispatch(PromotionAttributeConfigurationActions.GetAllAttributeConfigs).pipe(
            tap(() => {
                const attribute = this.store.selectSnapshot(PromotionAttributeConfigurationState.attributeById(action.attributeId));
                if (attribute) {
                    ctx.patchState({
                        attributeForm: {
                            model: {
                                attributeId: attribute.attributeId,
                                displayName: attribute.displayName,
                                attributeLevel: attribute.attributeLevel,
                                attributeType: attribute.attributeTypeCode,
                                valueOptions: attribute.attributeValueOptions?.map(valueOption => valueOption.attributeValue) || []
                            }
                        }
                    });
                }
            })
        );
    }

    @Action(PromotionAttributeConfigurationActions.ClearForm)
    clearForm(ctx: StateContext<PromotionAttributeConfigurationStateModel>): void {
        ctx.patchState({
            attributeForm: {
                model: undefined,
                dirty: false,
                status: '',
                errors: {}
            }
        });
    }
}
