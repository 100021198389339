<div class="nb-form-field select-field">
    <label for="{{id}}" [ngClass]="{required}" class="label">
        {{label}}
    </label>
    <jmcc-tooltip-icon-button *ngIf="tooltip" [icon]="'question-mark-circle-outline'" [tooltip]="tooltip"
    [tooltipPosition]="tooltipPosition"></jmcc-tooltip-icon-button>
    <nb-select id="{{id}}" placeholder="{{placeholder}}" shape="semi-round" [size]="size" fullWidth
        [formControl]="control" [status]="getStatus()" [multiple]="multiSelect" [scrollStrategy]="'noop'">
        <nb-option-group *ngFor="let group of optionGroups" [title]="group.displayValue">
            <nb-option *ngFor="let option of group.options" [value]="option.key">
                {{option.displayValue}}</nb-option>
        </nb-option-group>
        <nb-option *ngFor="let option of options" [value]="option.key">
            {{option.displayValue}}</nb-option>
    </nb-select>
    <div class="error-container">
        <span *ngIf="control.dirty && !control.valid" id="{{id}}ErrorMessage"
            class="status-danger">{{getErrorMessage()}}</span>
    </div>
</div>
