import { Directive, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import * as moment from 'moment';
import { Moment } from 'moment';
import { Subject } from 'rxjs';
import { Time } from '@angular/common';
import { filter, map, takeUntil } from 'rxjs/operators';
import { NgControl } from '@angular/forms';
import { NbDateTimePickerComponent } from '@nebular/theme';

export interface ChangedTime {
    previousValue: Time;
    currentValue: Time;
    firstChange: boolean;
}

@Directive({ selector: '[jmccDateTimePicker]' })
export class DateTimePickerDirective implements OnInit, OnChanges, OnDestroy {
    @Input('jmccDateTimePicker')
    dateTimePicker: NbDateTimePickerComponent<Moment>;

    @Input()
    defaultHour: number;

    @Input()
    defaultMinute: number;

    @Output()
    timeChange = new EventEmitter<ChangedTime>();

    currentTime: Time;
    destroyed$ = new Subject();

    constructor(private ngControl: NgControl) {
    }

    ngOnInit(): void {
        // Validate the developer has set the NbDateTimePickerComponent instance
        if (!this.dateTimePicker) {
            throw Error(`Missing NbDateTimePickerComponent instance. Example: <input [jmccDateTimePicker]="dateTimePicker" /> <nb-date-timepicker #dateTimePicker />`);
        }

        // The NbDateTimePicker `dateTimeChange` event doesn't fire when the user modifies the date/time value
        // in the text box that the component is bound to. We get around that by listening for an empty value
        // change on the NgControl.
        this.ngControl.valueChanges.pipe(
            filter(value => !value),
            takeUntil(this.destroyed$)
        ).subscribe(() => this.onClearDateTime());

        // The Nebular DateTimePickerComponent doesn't have an event for when the user picks a time, so we
        // manually create one. If the current and previous time values are not equal, then we know the time changed.
        this.dateTimePicker.dateTimeChange.pipe(
            map(value => this.getTime(value)),
            filter(value => !this.areTimesEqual(value, this.currentTime)),
            takeUntil(this.destroyed$)
        ).subscribe(value => this.onTimeChange(value));
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.defaultHour || changes.defaultMinute) {
            // The Nebular DateTimePickerComponent `value` property doesn't populate for initial values. In order to
            // reliably get the initial or current value, we have to get the current value from the `NgControl` `value`
            // property.
            //
            // As long as there's no value, the user's chosen time won't be overwritten, and we can set the
            // default date picker time.
            if (!this.ngControl.value) {
                this.setDatePickerDefaultTime();
            }
        }
    }

    ngOnDestroy(): void {
        this.destroyed$.next();
        this.destroyed$.complete();
    }

    setDatePickerDefaultTime(): void {
        // We can't just check `!this.defaultHour` and `!this.defaultMinute` because zero is falsy, and we want to allow zero
        const hasDefaultHour = this.defaultHour !== undefined && this.defaultHour !== null;
        const hasDefaultMinute = this.defaultMinute !== undefined && this.defaultMinute !== null;

        if (hasDefaultHour || hasDefaultMinute) {
            this.dateTimePicker.value = moment().set({
                hour: this.defaultHour,
                minute: this.defaultMinute
            });
        }
    }

    getTime(value: Moment): Time {
        let time: Time;

        if (value) {
            time = {
                hours: parseInt(value.format('HH'), 10),
                minutes: parseInt(value.format('mm'), 10)
            };
        }

        return time;
    }

    onTimeChange(value: Time): void {
        this.timeChange.next({
            previousValue: this.currentTime,
            currentValue: value,
            firstChange: this.currentTime === undefined
        });

        this.currentTime = value;
    }

    onClearDateTime(): void {
        this.setDatePickerDefaultTime();
    }

    areTimesEqual(value1: Time, value2: Time): boolean {
        return value1?.hours === value2?.hours && value1?.minutes === value2?.minutes;
    }
}
