export type LocalizedDurationUnitDisplay = 'short' | 'narrow' | 'long';

export class LocalizedDurationUtil {
    static format(locale: string, value: number, unitDisplay: LocalizedDurationUnitDisplay = 'short'): string {
        if (value === null || value === undefined) {
            return '';
        }

        const formattedParts: string[] = [];

        const days = Math.floor(value / (24 * 60 * 60 * 1000));
        let remainingMs = value % (24 * 60 * 60 * 1000);

        if (days) {
            formattedParts.push(
                Intl.NumberFormat(locale, { style: 'unit', unit: 'day', unitDisplay }).format(days)
            );
        }

        const hours = Math.floor(remainingMs / (60 * 60 * 1000));
        remainingMs = remainingMs % (60 * 60 * 1000);

        if (hours) {
            formattedParts.push(
                Intl.NumberFormat(locale, { style: 'unit', unit: 'hour', unitDisplay }).format(hours)
            );
        }

        const minutes = Math.floor(remainingMs / (60 * 1000));
        remainingMs = remainingMs % (60 * 1000);

        if (minutes) {
            formattedParts.push(
                Intl.NumberFormat(locale, { style: 'unit', unit: 'minute', unitDisplay }).format(minutes)
            );
        }

        const seconds = Math.floor(remainingMs / 1000);
        remainingMs = remainingMs % 1000;

        if (seconds) {
            formattedParts.push(
                Intl.NumberFormat(locale, { style: 'unit', unit: 'second', unitDisplay }).format(seconds)
            );
        }

        if (remainingMs) {
            formattedParts.push(
                Intl.NumberFormat(locale, { style: 'unit', unit: 'millisecond', unitDisplay }).format(remainingMs)
            );
        }

        const listFormat = new Intl.ListFormat(locale, { style: 'long', type: 'conjunction' });
        return listFormat.format(formattedParts);
    }
}
