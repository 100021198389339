import {
    IConfig,
    IDeviceConfiguration,
    IPaymentTerminalConfiguration, IPrinterConfiguration, NewConfigSession,
} from '../interfaces/config-management/config-management.interface';

export namespace ConfigManagementActions {

    export class LoadConfigMetadata {
        static readonly type = '[Config Management] Load Config Metadata';

        constructor(public includeConfiguration?: boolean) {
        }
    }

    export class LoadHistoricalSessions {
        static readonly type = '[Config Management] Load Historical Sessions';

        constructor() {
        }
    }

    export class SearchBasicConfig {
        static readonly type = '[Config Management] Search Basic Config';
    }

    export class UpdateBasicConfigSearchFilter {
        static readonly type = '[Config Management] Update Basic Config Search Filter';

        constructor(public search: string) {
        }
    }

    export class UpdateBasicConfigTagsFilter {
        static readonly type = '[Config Management] Update Basic Config Tags Filter';

        constructor(public tags: Record<string, string>) {
        }
    }

    export class StartNewConfigSession {
        static readonly type = '[Config Management] Start Config Session';

        constructor(public newConfigSession: NewConfigSession) {
        }
    }

    export class CancelConfigSession {
        static readonly type = '[Config Management] Cancel Config Session';

        constructor(public editGroupId: string) {
        }
    }

    export class SaveConfigSession {
        static readonly type = '[Config Management] Save Config Session';

        constructor() {
        }
    }

    export class RemoveConfigSession {
        static readonly type = '[Config Management] Remove Config Session';

        constructor(public editGroupId: string) {
        }
    }

    export class ResumeConfigSession {
        static readonly type = '[Config Management] Resume Config Session';

        constructor(public editGroupId: string) {
        }
    }

    export class UpdateSessionConfigs {
        static readonly type = '[Config Management] Update Session Configs';

        constructor(public configs: IConfig[]) {
        }
    }

    export class AddNewTagValue {
        static readonly type = '[Config Management] Add New Tag Value';

        constructor(public tagName: string, public tagValue: string) {
        }
    }

    export class LoadTags {
        static readonly type = '[Config Management] Load Tags';
    }

    export class GenerateUniqueDeviceHardwareId {
        static readonly type = '[Config Management] Generate Unique Device Hardware Id';
    }

    export class LoadDeviceConfigurations {
        static readonly type = '[Config Management] Load Device Configurations';

        constructor(public businessUnitId: string) {
        }
    }

    export class SaveDeviceConfiguration {
        static readonly type = '[Config Management] Save Device Configuration';

        constructor(public device: IDeviceConfiguration) {
        }
    }

    export class DeleteDeviceConfiguration {
        static readonly type = '[Config Management] Delete Device Configuration';

        constructor(public device: IDeviceConfiguration) {
        }
    }

    export class LoadPaymentTerminalConfigurations {
        static readonly type = '[Config Management] Load Payment Terminal Configurations';

        constructor(public businessUnitId: string) {
        }
    }

    export class SavePaymentTerminalConfiguration {
        static readonly type = '[Config Management] Save Payment Terminal Configuration';

        constructor(public paymentTerminal: IPaymentTerminalConfiguration) {
        }
    }

    export class DeletePaymentTerminalConfiguration {
        static readonly type = '[Config Management] Delete Payment Terminal Configuration';

        constructor(public paymentTerminal: IPaymentTerminalConfiguration) {
        }
    }

    export class LoadPrinterConfigurations {
        static readonly type = '[Config Management] Load Printer Configurations';

        constructor(public businessUnitId: string) {
        }
    }

    export class SavePrinterConfiguration {
        static readonly type = '[Config Management] Save Printer Configuration';

        constructor(public printer: IPrinterConfiguration) {
        }
    }

    export class DeletePrinterConfiguration {
        static readonly type = '[Config Management] Delete Printer Configuration';

        constructor(public printer: IPrinterConfiguration) {
        }
    }


    export class LoadPrinterTypes {
        static readonly type = '[Config Management] Load Printer Types';

        constructor() {
        }
    }

    export class LoadAppIds {
        static readonly type = '[Config Management] Load App IDs';

        constructor() {
        }
    }

    export class LoadDeviceAppIds {
        static readonly type = '[Config Management] Load Device App IDs';
    }

    export class LoadBusinessUnits {
        static readonly type = '[Config Management] Load Business Units';
    }

    export class LoadCountries {
        static readonly type = '[Config Management] Load Countries';
    }

    export class LoadStates {
        static readonly type = '[Config Management] Load States';
    }

    export class LoadDevices {
        static readonly type = '[Config Management] Load Devices';
    }

    export class LoadDeviceTypes {
        static readonly type = '[Config Management] Load Device Types';
    }

    export class LoadConfigListValues {
        static readonly type = '[Config Management] Load Config List Values';
    }
}
