import { Injectable } from '@angular/core';
import { Action, createSelector, Selector, State, StateContext, StateToken, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ContextService } from '../services/context.service';
import { IPreference } from '../interfaces/context.interface';
import { AppConfigurationActions } from './app-configuration.actions';

export class AppConfigurationStateModel {
    public preferences: IPreference[];
}

const APP_CONFIGURATION_STATE_TOKEN = new StateToken<AppConfigurationStateModel>('appConfiguration');

@State<AppConfigurationStateModel>({
    name: APP_CONFIGURATION_STATE_TOKEN,
    defaults: {
        preferences: [],
    },
})
@Injectable()
export class AppConfigurationState {

    constructor(private contextService: ContextService,
                private store: Store) {
    }

    static preference(applicationId: string, preferenceId: string) {
        return createSelector([AppConfigurationState], (state: AppConfigurationStateModel): IPreference =>
            state.preferences?.find(preference => (preference.preferenceId === preferenceId) && (preference.applicationId === applicationId)));
    }

    static preferencesByApp(applicationId: string) {
        return createSelector([AppConfigurationState], (state: AppConfigurationStateModel): IPreference[] =>
            state.preferences?.filter(preference => preference.applicationId === applicationId));
    }

    @Selector()
    static preferences(state: AppConfigurationStateModel): IPreference[] {
        return state.preferences;
    }

    @Action(AppConfigurationActions.LoadPreferences)
    loadPreferences(ctx: StateContext<AppConfigurationStateModel>, action: AppConfigurationActions.LoadPreferences): Observable<IPreference[]> {
        return this.contextService.getPreferenceValues(action.applicationId).pipe(
            tap((result) => {
                const filteredAppPreferences = [...ctx.getState().preferences.filter(preference => preference.applicationId !== action.applicationId)];
                filteredAppPreferences.push(...result);
                ctx.patchState({
                    preferences: filteredAppPreferences
                });
            })
        );
    }

    @Action(AppConfigurationActions.SavePreference)
    savePreference(ctx: StateContext<AppConfigurationStateModel>, action: AppConfigurationActions.SavePreference): Observable<void> {
        return this.contextService.savePreference(action.applicationId, action.preferenceId, action.value, action.preferenceType).pipe(
            tap(() => {
                const filteredAppPreferences =
                    [...ctx.getState().preferences.filter(preference =>
                        (preference.applicationId !== action.applicationId)
                        && (preference.preferenceId !== action.preferenceId))];
                filteredAppPreferences.push(
                    {
                        preferenceId: action.preferenceId,
                        applicationId: action.applicationId,
                        preferenceValue: action.value,
                    }
                );
                ctx.patchState({
                    preferences: filteredAppPreferences
                });
            })
        );
    }
}
