import { AdvancedSearchRule } from '../interfaces/promotion/advanced-search-rule.interface';

export namespace PromotionDashboardActions {
    export class GetDashboardData {
        static readonly type = '[Promotion API] Dashboard Data';
        constructor(public forceReload?: boolean, public searchParams?: AdvancedSearchRule[], public matchType?: string) {}
    }

    export class ResetDashboard {
        static readonly type = '[Promotion Dashboard] Reset Dashboard';
    }

    export class ClearFilters {
        static readonly type = '[Promotion Dashboard] Clear Filters';
    }

    export class ClearSearch {
        static readonly type = '[Promotion Dashboard] Clear Search';
    }

    export class DeletePromotion {
        static readonly type = '[Promotion Dashboard] Delete Promotion';
        constructor(public id: string) { }
    }

    export class SetSelectedCampaignId {
        static readonly type = '[Promotion Dashboard] Set Selected Campaign';

        constructor(public campaignId: string) {
        }
    }
}
