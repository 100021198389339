import { Action, Selector, State, StateContext, StateToken } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import {
    IGetReservedPromoCodePatternsResponse,
    IPromoCodePattern
} from '../interfaces/promotion/promotion-offers.interface';
import { PromoCodeActions } from './promo-code.actions';
import { PromotionService } from '../services/promotion.service';

export class PromoCodeStateModel {
    promoCodePatterns: IPromoCodePattern[];
}

const PROMO_CODE_STATE_TOKEN = new StateToken<PromoCodeStateModel>('promoCode');

@State<PromoCodeStateModel>({
    name: PROMO_CODE_STATE_TOKEN,
    defaults: {
        promoCodePatterns: [],
    }
})
@Injectable()
export class PromoCodeState {

    constructor(private promotionsService: PromotionService) { }

    @Selector()
    static promoCodePatterns(state: PromoCodeStateModel): IPromoCodePattern[] {
        return state.promoCodePatterns;
    }

    @Action(PromoCodeActions.LoadReservedPromoCodePatterns)
    loadReservedPromoCodePatterns(ctx: StateContext<PromoCodeStateModel>, action: PromoCodeActions.LoadReservedPromoCodePatterns): Observable<IGetReservedPromoCodePatternsResponse> {
        return this.promotionsService.getReservedPromoCodes(action.after).pipe(
            tap((result: IGetReservedPromoCodePatternsResponse) => {
                if (result.promoCodePatterns) {
                    ctx.patchState({ promoCodePatterns: result.promoCodePatterns });
                }
            })
        );
    }
}
