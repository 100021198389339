import { ObjectUtil } from './object.util';
import {
    ConfigMetaTypeCode,
    IConfig,
    IConfigEditGroup,
    IConfigMeta,
    IConfigMetaGroup,
    IConfigTag
} from '../interfaces/config-management/config-management.interface';
import { NumberUtil } from './number.util';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';

export class ConfigUtil {
    static isEmptyOrAny(value: string): boolean {
        return ObjectUtil.isValueEmpty(value) || value === '*';
    }

    static isDefault(tags: Record<string, string>): boolean {
        const tagsCount = Object.keys(tags || {}).length;
        const defaultTags = Object.keys(tags || {}).filter(key => this.isEmptyOrAny(tags[key]));

        return tagsCount > 0 && tagsCount === defaultTags?.length;
    }

    static findDefault(configs: IConfig[]): IConfig {
        const defaultConfigs = configs?.filter(config => this.isDefault(config.tags));
        return defaultConfigs?.[0];
    }

    static mapToTags(configTas: IConfigTag[]): Record<string, string> {
        const tags = {};

        configTas?.forEach(configTag => {
            tags[configTag.tagName] = configTag.tagValue;
        });

        return tags;
    }

    static mapToConfigTags(tags: Record<string, string>): IConfigTag[] {
        const configTags: IConfigTag[] = [];

        Object.keys(tags || {}).forEach(key => {
            configTags.push({
                tagName: key,
                tagValue: tags[key]
            });
        });

        return configTags;
    }

    static hasReceiptConfig(configs: IConfig[]): boolean {
        return configs?.some(config => this.isReceiptConfig(config));
    }

    static isReceiptConfig(config: IConfig): boolean {
        return config?.configName.startsWith('openpos.receipt.');
    }

    static getValueOrDefault(configValue: string, configMeta: IConfigMeta): any {
        let mappedConfigValue = this.mapValue(configValue, configMeta.configType);

        if (ObjectUtil.isValueEmpty(mappedConfigValue)) {
            const defaultConfig = this.findDefault(configMeta.configs);
            mappedConfigValue = this.mapValue(defaultConfig?.configValue, configMeta.configType);
        }

        return mappedConfigValue;
    }

    static populateForm(configMetaGroups: IConfigMetaGroup[], editSession: IConfigEditGroup, existingConfigs: IConfig[], form: FormGroup, formBuilder: FormBuilder): void {
        // Map the config tag structure to the structure used by AbstractTaggedModel
        const tags = ConfigUtil.mapToTags(editSession.tags);
        // Map configs by name for quick lookup when matching the edit value to the corresponding config meta
        const editConfigsByName = this.mapConfigsByName(editSession.configs);

        (form.controls.configs as FormArray<FormGroup>)?.clear();

        configMetaGroups.forEach(configMetaGroup => {
            configMetaGroup.configMetas.forEach(configMeta => {
                const editConfig = editConfigsByName?.[configMeta.configName];
                const mappedConfigValue = this.getValueOrDefault(editConfig?.configValue, configMeta);

                const data: IConfig = {
                    configName: configMeta.configName,
                    configValue: mappedConfigValue,
                    effectiveStartTime: editSession?.effectiveStartTime,
                    sequenceNumber: editConfig?.sequenceNumber,
                    tags
                };

                const configForm = formBuilder.group(data);
                (form.controls.configs as FormArray<FormGroup>)?.push(configForm);
            });
        });
    }

    static mapConfigsByName(configs: IConfig[]): Record<string, IConfig> {
        const configsByName = {};

        configs?.forEach(config => {
            configsByName[config.configName] = config;
        });

        return configsByName;
    }

    static mapValue(value: string, type?: ConfigMetaTypeCode): any {
        let result = null;
        let parsedResult;

        switch (type) {
            case 'BOOLEAN':
                if (!ObjectUtil.isValueEmpty(value)) {
                    result = value?.toString().toLowerCase() === 'true';
                }
                break;
            case 'LIST':
                if (!ObjectUtil.isValueEmpty(value)) {
                    result = value.toString();
                }
                break;
            case 'NUMBER':
                parsedResult = parseFloat(value?.toString());

                if (NumberUtil.isNumber(parsedResult)) {
                    result = parsedResult;
                }
                break;
            default:
                result = value;
        }

        return result;
    }

    static findConfigInEditGroup(config: IConfig, configEditGroup: IConfigEditGroup): IConfig {
        const editConfigTags = this.mapToTags(configEditGroup?.tags);

        const matchingEditConfig = configEditGroup?.configs.find(editConfig =>
            editConfig.configName === config.configName &&
            editConfig.effectiveStartTime === config.effectiveStartTime &&
            editConfig.sequenceNumber === config.sequenceNumber &&
            this.areTagEqual(editConfigTags, config.tags)
        );

        return matchingEditConfig;
    }

    static allUniqueTagSets(configMetaGroups: IConfigMetaGroup[]): Record<string, string>[] {
        const uniqueTagSets = new Set<string>();

        configMetaGroups.forEach(configMetaGroup => {
            configMetaGroup.configMetas.forEach(configMeta => {
                configMeta.configs.forEach(config => {
                    const tagSetString = JSON.stringify(config.tags);
                    uniqueTagSets.add(tagSetString);
                });
            });
        });

        return Array.from(uniqueTagSets).map(tagSetString => JSON.parse(tagSetString));
    }

    static hasTags(config: IConfig, tags: Record<string, string>): boolean {
        return Object.keys(tags || {}).some(key => !this.isEmptyOrAny(tags[key]) && config.tags[key] === tags[key]);
    }

    static areTagEqual(tags1: Record<string, string>, tags2: Record<string, string>): boolean {
        const equalTagNames = Object.keys(tags1 || {}).filter(tagName => {
            if (this.isEmptyOrAny(tags1[tagName]) && this.isEmptyOrAny(tags2?.[tagName])) {
                return true;
            }

            return tags1[tagName] === tags2?.[tagName];
        });

        return equalTagNames.length === Object.keys(tags1).length;
    }
}
