import { Action, Selector, State, StateContext, StateToken } from '@ngxs/store';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { PromotionService } from '../services/promotion.service';
import { PromotionLabelManagementActions } from './promotion-label-management.actions';
import { ILabelManagementData } from '../interfaces/promotion/label-management.interface';

export class PromotionLabelManagementStateModel {
    labelManagementData: ILabelManagementData;
}

const PROMOTION_LABEL_MANAGEMENT_STATE_TOKEN = new StateToken<PromotionLabelManagementStateModel>('promotionLabelManagement');

@State<PromotionLabelManagementStateModel>({
    name: PROMOTION_LABEL_MANAGEMENT_STATE_TOKEN,
    defaults: {
        labelManagementData: {
            campaignLabels: [],
            promotionLabels: [],
        },
    },
})
@Injectable()
export class PromotionLabelManagementState {
    constructor(private promotionService: PromotionService) {
    }

    @Selector()
    static labelManagementData(state: PromotionLabelManagementStateModel): ILabelManagementData {
        return state.labelManagementData;
    }

    @Action(PromotionLabelManagementActions.GetLabelManagementData)
    getLabelManagementData(ctx: StateContext<PromotionLabelManagementStateModel>): Observable<ILabelManagementData> {
        return this.promotionService.getLabelManagementData().pipe(
            tap((result: ILabelManagementData) => {
                if (result) {
                    ctx.patchState({
                        labelManagementData: result,
                    });
                }
            }),
        );
    }
}
