import { Injectable } from '@angular/core';
import { NbMenuItem } from '@nebular/theme';
import { Action, createSelector, Selector, State, StateContext, StateToken, Store } from '@ngxs/store';
import { switchMap, tap } from 'rxjs/operators';
import { UserService } from '../services/users.service';
import { IUser } from '../interfaces/user.interface';
import { NbAuthService } from '@nebular/auth';
import { AuthorizedActions } from './authorized.actions';
import { ITeam } from '../interfaces/team.interface';
import { NotificationsActions } from './notifications.actions';
import { INotificationSetting } from '../interfaces/notification.interface';
import { Observable, of } from 'rxjs';
import { IUserResponse } from '../interfaces/user-response.interface';
import { IGenericServerResponse } from '../interfaces/generic-server-response';
import { Router } from '@angular/router';

export class AuthorizedStateModel {
    authorizedUser: IUser;
    authorizedUserTeams: ITeam[];
    authorizedUserNotificationSettings: INotificationSetting[];
    mainNavMenuItems: NbMenuItem[];
    promoNavMenuItems: NbMenuItem[];
    configNavMenuItems: NbMenuItem[];
    deploymentsNavMenuItems: NbMenuItem[];
    electronicJournalNavMenuItems: NbMenuItem[];
    configManagementNavMenuItems: NbMenuItem[];
    coreDataNavMenuItems: NbMenuItem[];
}

const AUTHORIZED_STATE_TOKEN = new StateToken<AuthorizedStateModel>('authorized');

@State<AuthorizedStateModel>({
    name: AUTHORIZED_STATE_TOKEN,
    defaults: {
        authorizedUser: null,
        authorizedUserTeams: [],
        authorizedUserNotificationSettings: [],
        mainNavMenuItems: [],
        configNavMenuItems: [],
        promoNavMenuItems: [],
        deploymentsNavMenuItems: [],
        electronicJournalNavMenuItems: [],
        configManagementNavMenuItems: [],
        coreDataNavMenuItems: []
    },
})
@Injectable()
export class AuthorizedState {

    constructor(
        private store: Store,
        private userService: UserService,
        private authService: NbAuthService,
        private router: Router
    ) {
        this.authService.onAuthenticationChange()
            .subscribe((loggedIn: boolean) => {
                if (loggedIn) {
                    this.store.dispatch(AuthorizedActions.LoggedIn);
                } else {
                    this.store.dispatch(AuthorizedActions.LoggedOut);
                }
            });
    }

    static hasAppAccess(applicationId: string, accessType: string, teamMemberRoleRequirement?: string) {
        return createSelector([AuthorizedState], (state: AuthorizedStateModel) => {
            if (teamMemberRoleRequirement) {
                const teamsWithAccess = state.authorizedUserTeams?.filter(team => team.appAccessList?.some(access => access.applicationId === applicationId && access.accessType === accessType));
                return teamsWithAccess && state.authorizedUser?.teamMemberEntries?.some(member => member.teamRole === teamMemberRoleRequirement && teamsWithAccess.some(team => team.teamId === member.teamId));
            } else {
                return state.authorizedUser?.appAccessList?.some(access => access.applicationId === applicationId && access.accessType === accessType);
            }
        });
    }

    @Selector()
    static authorizedUser(state: AuthorizedStateModel): IUser {
        return state.authorizedUser;
    }

    @Selector()
    static authorizedUserTeams(state: AuthorizedStateModel): ITeam[] {
        return state.authorizedUserTeams;
    }

    @Selector()
    static authorizedUserNotificationSettings(state: AuthorizedStateModel): INotificationSetting[] {
        return state.authorizedUserNotificationSettings;
    }

    @Selector()
    static mainNavMenu(state: AuthorizedStateModel): NbMenuItem[] {
        return state.mainNavMenuItems;
    }

    @Selector()
    static configNavMenu(state: AuthorizedStateModel): NbMenuItem[] {
        return state.configNavMenuItems;
    }

    @Selector()
    static promoNavMenu(state: AuthorizedStateModel): NbMenuItem[] {
        return state.promoNavMenuItems;
    }

    @Selector()
    static deploymentsNavMenu(state: AuthorizedStateModel): NbMenuItem[] {
        return state.deploymentsNavMenuItems;
    }

    @Selector()
    static configManagementNavMenu(state: AuthorizedStateModel): NbMenuItem[] {
        return state.configManagementNavMenuItems;
    }

    @Selector()
    static coreDataNavMenu(state: AuthorizedStateModel): NbMenuItem[] {
        return state.coreDataNavMenuItems;
    }

    @Selector()
    static electronicJournalNavMenu(state: AuthorizedStateModel): NbMenuItem[] {
        return state.electronicJournalNavMenuItems;
    }

    @Action(AuthorizedActions.LoggedIn)
    getAuthorizedItems(ctx: StateContext<AuthorizedStateModel>): Observable<AuthorizedStateModel> {
        return this.userService.getAuthorizedItems().pipe(
            tap(result => {
                ctx.setState(result);
                this.store.dispatch(NotificationsActions.LoadNotifications);
            })
        );
    }

    @Action(AuthorizedActions.RefreshAuthorizedItems)
    refreshAuthorizedItems(ctx: StateContext<AuthorizedStateModel>): Observable<AuthorizedStateModel> {
        return this.authService.getToken().pipe(switchMap((token) => {
            if (token?.isValid()) {
                return this.getAuthorizedItems(ctx);
            } else {
                return of({
                    authorizedUser: null,
                    authorizedUserTeams: [],
                    authorizedUserNotificationSettings: [],
                    mainNavMenuItems: [],
                    configNavMenuItems: [],
                    promoNavMenuItems: [],
                    deploymentsNavMenuItems: [],
                    electronicJournalNavMenuItems: [],
                    configManagementNavMenuItems: [],
                    coreDataNavMenuItems: []
                });
            }
        }));
    }

    @Action(AuthorizedActions.LoggedOut)
    logout(): void {
        this.router.navigate(['/auth/logout']);
    }

    @Action(AuthorizedActions.EditAuthorizedUser)
    editAuthorizedUser(ctx: StateContext<AuthorizedStateModel>, action: AuthorizedActions.EditAuthorizedUser): Observable<IUserResponse> {
        const authorizedUser = ctx.getState().authorizedUser;
        if (authorizedUser) {
            return this.userService.editUser({
                name: action.name,
                email: action.email,
                icon: authorizedUser.icon,
                userId: authorizedUser.userId
            }).pipe(
                tap(result => {
                    if (result.success && result.user) {
                        ctx.patchState({ authorizedUser: result.user });
                    } else {
                        throw new Error(result.message);
                    }
                })
            );
        }
    }

    @Action(AuthorizedActions.UpdateAuthorizedUserNotificationSettings)
    updateAuthorizedUserNotificationSettings(ctx: StateContext<AuthorizedStateModel>, action: AuthorizedActions.UpdateAuthorizedUserNotificationSettings): Observable<IGenericServerResponse> {
        const authorizedUser = ctx.getState().authorizedUser;
        if (authorizedUser) {
            return this.userService.updateUserNotificationSettings(authorizedUser.userId, action.notificationSettings).pipe(
                tap(result => {
                    if (result.success) {
                        ctx.patchState({ authorizedUserNotificationSettings: action.notificationSettings });
                    } else {
                        throw new Error(result.message);
                    }
                })
            );
        }
    }

    @Action(AuthorizedActions.UpdateAuthorizedPassword)
    updatePassword(ctx: StateContext<AuthorizedStateModel>, action: AuthorizedActions.UpdateAuthorizedPassword): Observable<IUserResponse> {
        return this.userService.updatePassword(action.request).pipe(
            tap(result => {
                if (result.success && result.user) {
                    ctx.patchState({ authorizedUser: result.user });
                } else {
                    throw new Error(result.message);
                }
            })
        );
    }
}
