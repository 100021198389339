export enum TimeZoneOffsetDirection {
    Ahead,
    Behind
}

export interface TimeZoneOffset {
    direction: TimeZoneOffsetDirection;
    hours: number;
    minutes: number;
}

export class TimeZoneOffsetUtil {
    static fromMilliseconds(millisecondsOffset: number): TimeZoneOffset {
        if (millisecondsOffset === null || millisecondsOffset === undefined) {
            millisecondsOffset = 0;
        }

        const absMillisecondsOffset = Math.abs(millisecondsOffset);

        const direction = millisecondsOffset < 0 ? TimeZoneOffsetDirection.Behind : TimeZoneOffsetDirection.Ahead;
        const hours = Math.floor(absMillisecondsOffset / 1000 / 60 / 60);
        const minutes = absMillisecondsOffset / 1000 / 60 % 60;

        return {
            direction,
            hours,
            minutes
        };
    }

    static format(offset: TimeZoneOffset): string {
        const direction = offset.direction === TimeZoneOffsetDirection.Behind ? '-' : '+';
        const hoursFormat = offset.hours.toString().padStart(2, '0');
        const minutesFormat = offset.minutes.toString().padStart(2, '0');

        return `${direction}${hoursFormat}:${minutesFormat}`;
    }
}
