import { IStagedItemGroup } from '../interfaces/item-group.interface';
import { IUploadedFile } from '../interfaces/promotion/uploaded-file.interface';

export namespace ItemActions {

    export class AllItemGroups {
        static readonly type = '[Item] All Item Groups';
        constructor(public forceReload?: boolean) { }
    }

    export class FindItem {
        static readonly type = '[Item] Find Item';
        constructor(public itemId: string) { }
    }

    export class FindItemsByImport {
        static readonly type = '[Item] Find Items By Import';
        constructor(public uploadedFile: IUploadedFile, public separatorExp: RegExp) { }
    }

    export class FindItemsByPaste {
        static readonly type = '[Item] Find Items By Paste';
        constructor(public ids: string, public separatorExp: RegExp) { }
    }

    export class SearchStagedItemGroups {
        static readonly type = '[Item] Search Staged Item Groups';
        constructor(public searchString: string) { }
    }

    export class ClearSearch {
        static readonly type = '[Item] Clear Search';
    }

    export class ClearForm {
        static readonly type = '[Item] Clear Create Form';
    }

    export class LoadStagedItemGroup {
        static readonly type = '[Item] Load Staged Item Group';
        constructor(public stagedItemGroupName: string) { }
    }

    export class SaveStagedItemGroup {
        static readonly type = '[Item] Save Staged Item Group';
        constructor(public stagedItemGroup: IStagedItemGroup, public create: boolean) { }
    }

    export class DeleteStagedItemGroup {
        static readonly type = '[Item] Delete Staged Item Group';
        constructor(public stagedItemGroup: IStagedItemGroup) { }
    }
}
