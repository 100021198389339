export namespace IncludeTypeCode {
    export const INCLUDE = 'INCLUDE';
    export const EXCLUDE = 'EXCLUDE';
}

export const TRANS = 'TRANS';
export const ITEM = 'ITEM';

export namespace RewardTypeCode {
    export const AMT = 'AMT';
    export const PCT = 'PCT';
    export const PRICE = 'PRICE';
    export const PRICE_TOTAL = 'PRICE_TOTAL';
    export const PRICE_OVERRIDE = 'PRICE_OVERRIDE';
    export const TRN_AMT = 'TRN_AMT';
    export const TRN_PCT = 'TRN_PCT';
    export const CATEGORY = 'CATEGORY';
}

export namespace RewardCategoryTypeCode {
    export const AMT = 'AMT';
    export const PCT = 'PCT';
}

export namespace QualificationTypeCode {
    export const AMT = 'AMT';
    export const QTY = 'QTY';
    export const TRN_AMT = 'TRN_AMT';
    export const TRN_QTY = 'TRN_QTY';
    export const AMT_TOTAL = 'AMT_TOTAL';
}

export namespace QualificationItemsRewardPreference {
    export const ALLOW = 'ALLOW';
    export const PREVENT = 'PREVENT';
    export const REQUIRE = 'REQUIRE';
}

export namespace RewardApplicationTypeCode {
    export const LOWEST_PRICE = 'LOWEST_PRICE';
    export const HIGHEST_PRICE = 'HIGHEST_PRICE';
}

export namespace RewardBasePriceTypeCode {
    export const RETAIL = 'RETAIL';
    export const LIST = 'LIST';
}

export namespace QualificationApplicationTypeCode {
    export const LOWEST_PRICE = 'LOWEST_PRICE';
    export const HIGHEST_PRICE = 'HIGHEST_PRICE';
}

export interface IPromotion {
    autoApply?: boolean;
    createBy?: string;
    createTime?: string;
    externalId?: string;
    lastUpdateBy?: string;
    lastUpdateTime?: Date;
    longDescription?: string;
    maxUses?: number;
    promotionId?: string;
    promotionName?: string;
    promotionType?: string;
    qualifications?: IQualification[];
    qualifyApplicationTypeCode?: string;
    rewardApplicationTypeCode?: string;
    rewardOperatorTypeCode?: string;
    rewardBasePriceTypeCode?: string;
    rewards?: (IReward | IRewardCategory)[];
    singleUse?: boolean;
    vendorFunded?: boolean;
    promotionCopies?: IPromotionCopy[];
}

export interface IQualification {
    additionalFields?: object;
    createBy?: string;
    createTime?: string;
    extensions?: object;
    itemIds?: string[];
    lastUpdateBy?: string;
    lastUpdateTime?: string;
    promotionId: string;
    qualification: number;
    qualificationId?: string;
    qualificationItems?: IQualificationItem[];
    qualificationTypeCode: string;
    qualItemsRewardPreference?: string;
    rewardEligible?: boolean;
    tier?: number;
    nearMissConfiguration?: INearMiss;
}

export interface INearMiss {
    referenceId: string;
    referenceType: string;
    triggerAmount: number;
    message: string;
}

export interface IQualificationItem {
    additionalFields?: object;
    createBy?: string;
    createTime?: string;
    description?: string;
    extensions?: object;
    lastUpdateBy?: string;
    lastUpdateTime?: string;
    qualificationId?: string;
    qualificationItemId?: string;
    qualificationItemType: string;
    qualificationMerchandiseId: string;
    qualificationItemAttributes?: IQualificationItemAttribute[];
}

export interface IQualificationItemAttribute extends IPromotedItemAttribute {
    qualificationItemAttrId?: string;
    qualificationItemId?: string;
}

export interface IRewardItemAttribute extends IPromotedItemAttribute{
    rewardItemAttrId?: string;
    rewardItemId?: string;
}

export interface IPromotedItemAttribute {
    attributeId: string;
    operatorCode: string;
    rightHandSide: string;
}

export interface IReward {
    additionalFields?: object;
    createBy?: string;
    createTime?: string;
    extensions?: object;
    itemIds?: string[];
    lastUpdateBy?: string;
    lastUpdateTime?: string;
    maxRewardAmount: number;
    promotionId?: string;
    qualificationEligible?: boolean;
    qualificationProrate?: boolean;
    reward: number;
    rewardBasePriceCode: string;
    rewardId?: string;
    rewardItems?: IRewardItem[];
    rewardQuantity: number;
    rewardType: string;
    tier?: number;
    vendorFunded?: boolean;
    __modelType?: string;
}

export interface IRewardCategory extends IReward {
    categoryId: string;
    rewardCategoryType: string;
}

export interface IRewardItem {
    additionalFields?: object;
    createBy?: string;
    createTime?: string;
    description?: string;
    extensions?: object;
    lastUpdateBy?: string;
    lastUpdateTime?: string;
    rewardId?: string;
    rewardItemId?: string;
    rewardItemType: string;
    rewardMerchandiseId: string;
    vendorFunded?: boolean;
    rewardItemAttributes?: IRewardItemAttribute[];
}

export interface IPromotionCopy {
    copyText: string;
    displayOrder: number;
    promotionCopyId?: string;
    promotionId?: string;
}
