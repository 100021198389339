import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, StateToken } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import { IApp } from '../interfaces/app.interface';
import { ContextService } from '../services/context.service';
import { AuthorizedActions } from './authorized.actions';
import { AppActions } from './app.actions';
import { IAbout } from '../interfaces/about.interface';

export class AppStateModel {
    about: IAbout;
    enabledApps: IApp[];
    missingLicense: boolean;
}

const APP_STATE_TOKEN = new StateToken<AppStateModel>('app');

@State<AppStateModel>({
    name: APP_STATE_TOKEN,
    defaults: {
        about: null,
        enabledApps: [],
        missingLicense: true
    },
})
@Injectable()
export class AppState {

    constructor(private contextService: ContextService) {
    }

    @Selector()
    static about(state: AppStateModel): IAbout {
        return state.about;
    }

    @Selector()
    static enabledApps(state: AppStateModel): IApp[] {
        return state.enabledApps;
    }

    @Action(AppActions.GetAbout)
    getAbout(ctx: StateContext<AppStateModel>) {
        return this.contextService.getAbout().pipe(
            tap(result => ctx.patchState({
                about: result,
            })),
        );
    }

    @Selector()
    static missingLicense(state: AppStateModel): boolean {
        return state.missingLicense;
    }

    @Action(AuthorizedActions.LoggedIn)
    loggedIn(ctx: StateContext<AppStateModel>) {
        return this.contextService.getEnabledApps().pipe(
            tap(result => ctx.patchState({
                enabledApps: result.apps,
                missingLicense: result.missingLicense
            }))
        );
    }
}
