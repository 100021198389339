<div class="nb-form-field">
    <label for="{{id}}" [ngClass]="{required}" class="label">
        {{label}}
    </label>
    <nb-form-field>
        <input [nbDatepicker]="dateTimePicker"
               nbInput id="{{id}}"
               placeholder="{{placeholder}}"
               fieldSize="large"
               [formControl]="control"
               [status]="getStatus()"
               [autocomplete]="'off'"
               [jmccDateTimePicker]="dateTimePicker"
               [defaultHour]="defaultHour"
               [defaultMinute]="defaultMinute"
               (timeChange)="timeChange.emit($event)">
        <nb-icon nbSuffix icon="calendar-outline" (click)="focusInput()"></nb-icon>
        <nb-date-timepicker singleColumn #dateTimePicker [step]="30" format="MM/DD/yyyy HH:mm" [min]="minDate" [max]="maxDate">
        </nb-date-timepicker>
    </nb-form-field>
    <div class="error-container">
        <span *ngIf="control.dirty && !control.valid" id="{{id}}ErrorMessage"
            class="status-danger">{{getErrorMessage()}}</span>
    </div>
</div>
