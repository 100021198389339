import { Injectable } from '@angular/core';
import { Action, createSelector, Selector, State, StateContext, StateToken, Store } from '@ngxs/store';
import { Observable, of } from 'rxjs';
import { mergeMap, tap } from 'rxjs/operators';
import { NgxsForm } from '../interfaces/ngxs-form.interface';
import {
    GetAllNearMissMessagesResponse,
    INearMissMessage,
    NearMissMessageActionResponse,
} from '../interfaces/near-miss-message.interface';
import { NearMissMessageActions } from './near-miss-message.actions';
import { NearMissMessageService } from '../services/near-miss-message-service';

export class NearMissMessageStateModel {
    nearMissMessages: INearMissMessage[];
    nearMissMessageForm: NgxsForm<INearMissMessage>;
}

const NEAR_MISS_MESSAGE_STATE_TOKEN = new StateToken<NearMissMessageStateModel>('nearMissMessages');

@State<NearMissMessageStateModel>({
    name: NEAR_MISS_MESSAGE_STATE_TOKEN,
    defaults: {
        nearMissMessages: [],
        nearMissMessageForm: {
            model: undefined,
            dirty: false,
            status: '',
            errors: {}
        },
    }
})
@Injectable()
export class NearMissMessageState {
    constructor(private nearMissMessageService: NearMissMessageService, private store: Store) {
    }

    static nearMissMessageById(id: string) {
        return createSelector([NearMissMessageState], (state: NearMissMessageStateModel): INearMissMessage =>
            state.nearMissMessages?.find(nmm => nmm.id === id));
    }

    @Selector()
    static nearMissMessages(state: NearMissMessageStateModel): INearMissMessage[] {
        return state.nearMissMessages ?? [];
    }

    @Action(NearMissMessageActions.GetAllNearMissMessages)
    getAllNearMissMessages(
        ctx: StateContext<NearMissMessageStateModel>,
        action: NearMissMessageActions.GetAllNearMissMessages
    ): Observable<GetAllNearMissMessagesResponse> {
        if (!ctx.getState().nearMissMessages?.length || action.forceReload) {
            return this.nearMissMessageService.getAllNearMissMessages().pipe(
                tap((result: GetAllNearMissMessagesResponse) => {
                    result.nearMissMessages.sort((a, b) => (a.name ?? '').localeCompare(b.name ?? ''));
                    ctx.patchState({ nearMissMessages: result.nearMissMessages });
                })
            );
        }
        return of({} as GetAllNearMissMessagesResponse);
    }

    @Action(NearMissMessageActions.GetNearMissMessage)
    loadNearMissMessage(ctx: StateContext<NearMissMessageStateModel>, action: NearMissMessageActions.GetNearMissMessage) {
        return this.store.dispatch(new NearMissMessageActions.GetAllNearMissMessages(false)).pipe(
            tap(() => {
                this.patchNearMissMessageFormModel(ctx, action.nearMissMessageId);
            })
        );
    }

    @Action(NearMissMessageActions.SaveNearMissMessage)
    saveNearMissMessage(
        ctx: StateContext<NearMissMessageStateModel>,
        action: NearMissMessageActions.SaveNearMissMessage
    ): Observable<GetAllNearMissMessagesResponse> {
        if (action.nearMissMessage) {
            return this.nearMissMessageService.saveNearMissMessage(action.nearMissMessage).pipe(
                mergeMap((result: NearMissMessageActionResponse) => {
                    if (result.success) {
                        return this.getAllNearMissMessages(ctx, new NearMissMessageActions.GetAllNearMissMessages(true));
                    } else {
                        throw new Error(result.message);
                    }
                })
            );
        }
    }

    @Action(NearMissMessageActions.DeleteNearMissMessage)
    deleteNearMissMessage(
        ctx: StateContext<NearMissMessageStateModel>,
        action: NearMissMessageActions.DeleteNearMissMessage
    ): Observable<NearMissMessageActionResponse> {
        if (action.nearMissMessageId) {
            return this.nearMissMessageService.deleteNearMissMessage(action.nearMissMessageId).pipe(
                tap((result: NearMissMessageActionResponse) => {
                    if (result.success) {
                        return this.getAllNearMissMessages(
                            ctx, new NearMissMessageActions.GetAllNearMissMessages(true)
                        ).subscribe();
                    } else {
                        throw new Error(result.message);
                    }
                })
            );
        }
    }

    @Action(NearMissMessageActions.ClearForm)
    clearForm(ctx: StateContext<NearMissMessageStateModel>): void {
        ctx.patchState({
            nearMissMessageForm: {
                model: undefined,
                dirty: false,
                status: '',
                errors: {}
            }
        });
    }

    private patchNearMissMessageFormModel(ctx: StateContext<NearMissMessageStateModel>, nearMissMessageId: string): void {
        const nearMissMessage = this.store.selectSnapshot(NearMissMessageState.nearMissMessageById(nearMissMessageId));
        if (nearMissMessage) {
            ctx.patchState({
                nearMissMessageForm: {
                    model: {
                        id: nearMissMessage.id,
                        name: nearMissMessage.name,
                        message: nearMissMessage.message,
                    }
                }
            });
        }
    }
}
