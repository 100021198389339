import { Action, createSelector, Selector, State, StateContext, StateToken, Store } from '@ngxs/store';
import { Injectable } from '@angular/core';
import {
    DeploymentStatusCode,
    IDeployment,
    IDeploymentStatistics,
    IDeploymentTemplate,
    IInstallation,
    IInstallationGroup, IVersionColorMapping,
    IVersionedBusinessUnit, IVersionStatistics
} from '../interfaces/deployments/deployment.interface';
import { DeploymentsService } from '../services/deployments.service';
import { DeploymentsActions } from './deployments.actions';
import { tap } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { GetVersionedBusinessUnitsResponse } from '../interfaces/deployments/deployment-api.interface';
import {
    DeploymentsDataAggregatorService,
    UNKNOWN_DEPLOYMENT
} from '../services/deployments-data-aggregator.service';

export class DeploymentsStateModel {
    versionedBusinessUnits: { [packageName: string]: IVersionedBusinessUnit[] };
    packages: string[];
    deploymentStatistics: IDeploymentStatistics[];
    versionStatistics: IVersionStatistics[];
    deployments: IDeployment[];
    installGroups: IInstallationGroup[];
    templates: IDeploymentTemplate[];
    currentPackage: string;
    availableVersions: string[];
    versionColorMappings: IVersionColorMapping[];
}

const ACTIVE_DEPLOYMENTS_TOKEN = new StateToken<DeploymentsStateModel>('deployments');

@State<DeploymentsStateModel>({
    name: ACTIVE_DEPLOYMENTS_TOKEN,
    defaults: {
        versionedBusinessUnits: {},
        packages: [],
        deploymentStatistics: [],
        versionStatistics: [],
        deployments: [],
        installGroups: [],
        templates: [],
        currentPackage: 'point-of-sale',
        availableVersions: [],
        versionColorMappings: []
    }
})
@Injectable()
export class DeploymentsState {

    constructor(
        private store: Store,
        private deploymentsService: DeploymentsService,
        private deploymentsDataAggregatorService: DeploymentsDataAggregatorService
    ) {
    }

    static deploymentStatistic(deploymentId: string) {
        return createSelector([DeploymentsState], (state: DeploymentsStateModel) =>
            state.deploymentStatistics?.filter((stat) => stat.deployment?.deploymentId === deploymentId).at(0));
    }

    static deployment(deploymentId: string) {
        return createSelector([DeploymentsState], (state: DeploymentsStateModel) => {
            const deploymentsFound = state.deployments?.filter((deployment) => deployment?.deploymentId === deploymentId);
            return deploymentsFound ? deploymentsFound.at(0) : UNKNOWN_DEPLOYMENT;
        });
    }

    static colorOfVersion(version: string) {
        return createSelector([DeploymentsState], (state: DeploymentsStateModel) => {
            const mappingsFound = state.versionColorMappings?.filter((mapping) => mapping.version === version);
            return mappingsFound?.length > 0 ? mappingsFound.at(0).color : undefined;
        });
    }

    static deploymentTemplate(templateId: string) {
        return createSelector([DeploymentsState], (state: DeploymentsStateModel) =>
            state.templates?.filter((template) => template.templateId === templateId).at(0));
    }

    @Selector()
    static deploymentStatistics(state: DeploymentsStateModel): IDeploymentStatistics[] {
        return state.deploymentStatistics;
    }

    @Selector()
    static versionStatistics(state: DeploymentsStateModel): IVersionStatistics[] {
        return state.versionStatistics;
    }

    @Selector()
    static deployments(state: DeploymentsStateModel): IDeployment[] {
        return state.deployments;
    }

    @Selector()
    static activeDeployments(state: DeploymentsStateModel): IDeployment[] {
        return state.deployments?.filter(deployment => [DeploymentStatusCode.ACTIVE, DeploymentStatusCode.PAUSED, DeploymentStatusCode.DELAYED].includes(deployment.deploymentStatusCode));
    }

    @Selector()
    static futureDeployments(state: DeploymentsStateModel): IDeployment[] {
        return state.deployments?.filter(deployment => [DeploymentStatusCode.PENDING].includes(deployment.deploymentStatusCode));
    }

    @Selector()
    static historicDeployments(state: DeploymentsStateModel): IDeployment[] {
        return state.deployments?.filter(deployment => [DeploymentStatusCode.CANCELLED, DeploymentStatusCode.DONE].includes(deployment.deploymentStatusCode));
    }

    @Selector()
    static deploymentTemplates(state: DeploymentsStateModel): IDeploymentTemplate[] {
        return state.templates;
    }

    @Selector()
    static activeDeploymentTemplates(state: DeploymentsStateModel): IDeploymentTemplate[] {
        return state.templates?.filter(e => !e.archived);
    }

    @Selector()
    static installGroups(state: DeploymentsStateModel): IInstallationGroup[] {
        return state.installGroups;
    }

    @Selector()
    static installGroupNames(state: DeploymentsStateModel): string[] {
        return state.installGroups.map(e => e.groupName);
    }

    @Selector()
    static templates(state: DeploymentsStateModel): IDeploymentTemplate[] {
        return state.templates;
    }

    @Selector()
    static activeTemplates(state: DeploymentsStateModel): IDeploymentTemplate[] {
        return state.templates.filter(e => !e.archived);
    }

    @Selector()
    static currentPackage(state: DeploymentsStateModel): string {
        return state.currentPackage;
    }

    @Selector()
    static allPackages(state: DeploymentsStateModel): string[] {
        return state.packages;
    }

    @Selector()
    static availableVersions(state: DeploymentsStateModel): string[] {
        return state.availableVersions;
    }

    @Action(DeploymentsActions.LoadVersionedBusinessUnits)
    loadVersionedBusinessUnits(ctx: StateContext<DeploymentsStateModel>, action: DeploymentsActions.LoadVersionedBusinessUnits): Observable<GetVersionedBusinessUnitsResponse> {
        return this.deploymentsService.getVersionedBusinessUnits(action.packageName ? action.packageName : ctx.getState().currentPackage);
        // We do not save these to the state as with enough business units this will overflow the browser storage.
    }

    @Action(DeploymentsActions.LoadInstallationGroups)
    loadInstallationGroups(ctx: StateContext<DeploymentsStateModel>): Observable<IInstallationGroup[]> {
        return this.deploymentsService.getInstallationGroups().pipe(
            tap(result => {
                ctx.patchState({
                    installGroups: result
                });
            }));
    }

    @Action(DeploymentsActions.LoadTemplates)
    loadTemplates(ctx: StateContext<DeploymentsStateModel>): Observable<IDeploymentTemplate[]> {
        return this.deploymentsService.getAllDeploymentTemplates().pipe(
            tap(result => {
                ctx.patchState({
                    templates: result
                });
            }));
    }

    @Action(DeploymentsActions.SaveInstallationGroup)
    saveInstallationGroup(ctx: StateContext<DeploymentsStateModel>, action: DeploymentsActions.SaveInstallationGroup): Observable<any> {
        return this.deploymentsService.saveInstallationGroups(action.installationGroup);
    }

    @Action(DeploymentsActions.SaveTemplate)
    saveDeploymentTemplate(ctx: StateContext<DeploymentsStateModel>, action: DeploymentsActions.SaveTemplate): Observable<any> {
        return this.deploymentsService.saveDeploymentTemplate(action.deploymentTemplate);
    }

    @Action(DeploymentsActions.SaveDeployment)
    saveDeployment(ctx: StateContext<DeploymentsStateModel>, action: DeploymentsActions.SaveDeployment): Observable<any> {
        return this.deploymentsService.saveDeployment(action.deployment, ctx.getState().currentPackage);
    }

    @Action(DeploymentsActions.LoadDeployments)
    loadDeployments(ctx: StateContext<DeploymentsStateModel>, action: DeploymentsActions.LoadDeployments): Observable<IDeployment[]> {
        return this.deploymentsService.getAllDeployments(action.packageName ? action.packageName : ctx.getState().currentPackage).pipe(
            tap(result => {
                const colorMap = this.buildVersionColorMap(result);
                ctx.patchState({
                    deployments: result,
                    versionColorMappings: colorMap
                });
            }));
    }

    @Action(DeploymentsActions.LoadAvailableVersions)
    loadAvailableVersions(ctx: StateContext<DeploymentsStateModel>, action: DeploymentsActions.LoadAvailableVersions): Observable<string[]> {
        return this.deploymentsService.getAvailableVersions(action.packageName ? action.packageName : ctx.getState().currentPackage).pipe(
            tap(result => {
                ctx.patchState({
                    availableVersions: result
                });
            }));
    }

    @Action(DeploymentsActions.LoadPackages)
    loadPackages(ctx: StateContext<DeploymentsStateModel>): Observable<string[]> {
        return this.deploymentsService.getPackages().pipe(
            tap(result => {
                ctx.patchState({
                    packages: result,
                    currentPackage: result?.length > 0 ? result[0] : 'point-of-sale'
                });
            }));
    }

    @Action(DeploymentsActions.ChangePackage)
    changePackage(ctx: StateContext<DeploymentsStateModel>, action: DeploymentsActions.ChangePackage): void {
        ctx.patchState({
            currentPackage: action.packageName
        });
    }

    @Action(DeploymentsActions.GetInstallationsForGroup)
    getInstallationsForGroup(ctx: StateContext<DeploymentsStateModel>, action: DeploymentsActions.GetInstallationsForGroup): Observable<IInstallation[]> {
        return this.deploymentsService.getInstallationsByGroup(action.groupId, ctx.getState().currentPackage);
    }

    @Action(DeploymentsActions.LoadDeploymentStatistics)
    loadDeploymentStatistics(ctx: StateContext<DeploymentsStateModel>, action: DeploymentsActions.LoadDeploymentStatistics): Observable<GetVersionedBusinessUnitsResponse> {
        return this.deploymentsService.getVersionedBusinessUnits(action.packageName ? action.packageName : ctx.getState().currentPackage).pipe(
            tap(result => {
                const statsList: IDeploymentStatistics[] = [];
                ctx.getState().deployments?.forEach(deployment => {
                    const template = ctx.getState().templates?.find(e => e.templateId === deployment.templateId);
                    const unitsForDeployment = result.versionedBusinessUnits.filter(unit => this.isUnitCoveredByDeployment(unit, deployment, template));
                    const unitVersionMap = this.deploymentsDataAggregatorService.sortVersionedUnitsByVersion(unitsForDeployment);
                    statsList.push({
                        deployment: deployment,
                        numberOfStores: unitVersionMap.get(deployment.version) ? unitVersionMap.get(deployment.version).length : 0,
                        numberOfDevices: unitsForDeployment.reduce((sum, unit) => sum + unit.installations?.filter(install => install.version === deployment.version).length, 0),
                        errors: this.deploymentsDataAggregatorService.buildErrors(unitsForDeployment, deployment)
                    });
                });
                ctx.patchState({
                    deploymentStatistics: statsList
                });
            })
        );
    }

    @Action(DeploymentsActions.LoadVersionStatistics)
    loadVersionStatistics(ctx: StateContext<DeploymentsStateModel>, action: DeploymentsActions.LoadVersionStatistics): Observable<GetVersionedBusinessUnitsResponse> {
        return this.deploymentsService.getVersionedBusinessUnits(action.packageName ? action.packageName : ctx.getState().currentPackage).pipe(
            tap(result => {
                const unitsByVersion = this.deploymentsDataAggregatorService.sortVersionedUnitsByVersion(result.versionedBusinessUnits);
                const installsByVersion = this.deploymentsDataAggregatorService.sortInstallationsByVersion(result.versionedBusinessUnits.flatMap(e => e.installations));
                const statsList: IVersionStatistics[] = [];
                unitsByVersion.forEach((value, key) => {
                    statsList.push({
                        version: key,
                        numberOfDevices: installsByVersion.get(key) ? installsByVersion.get(key).length : 0,
                        numberOfStores: value.length
                    });
                });
                ctx.patchState({
                    versionStatistics: statsList
                });
            })
        );
    }

    @Action(DeploymentsActions.CancelDeployment)
    cancelDeployment(ctx: StateContext<DeploymentsStateModel>, action: DeploymentsActions.CancelDeployment): Observable<void> {
        return this.deploymentsService.cancelDeployment(action.deploymentId);
    }

    @Action(DeploymentsActions.AdvanceDeploymentManually)
    advanceDeploymentManually(ctx: StateContext<DeploymentsStateModel>, action: DeploymentsActions.AdvanceDeploymentManually): Observable<void> {
        return this.deploymentsService.manuallyAdvance(action.deploymentId).pipe(
            tap(result => {
                if (!result.success) {
                    return throwError(new Error(result.message));
                }
            })
        );
    }

    protected buildVersionColorMap(deployments: IDeployment[]) {
        const colorMappings: IVersionColorMapping[] = [];
        deployments.forEach(deployment => {
            if (!colorMappings.find(e => e.version === deployment.version)) {
                colorMappings.push({
                    color: deployment.color,
                    version: deployment.version
                });
            }
        });
        return colorMappings;
    }

    protected isUnitCoveredByDeployment(unit: IVersionedBusinessUnit, deployment: IDeployment, template: IDeploymentTemplate) {
        const unitsCovered = template?.steps.flatMap(step =>
            step.stepIndex <= deployment.currentStepIndex ? step.storeGroups.flatMap(group => group.members) : []);
        return unitsCovered?.some(coveredUnit => coveredUnit.businessUnitId === unit.businessUnit.businessUnitId);
    }
}
