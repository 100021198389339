import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { NbMenuItem, NbMenuService } from '@nebular/theme';
import { NGXLogger } from 'ngx-logger';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { SelectOption } from '../../../@forms/forms.interface';

@Component({
    selector: 'jmcc-local-nav-bar',
    styleUrls: ['./local-nav-bar.component.scss'],
    templateUrl: './local-nav-bar.component.html',
})
export class LocalNavBarComponent implements OnInit, OnDestroy {

    @Input()
    navMenu: NbMenuItem[];

    @Input()
    logoImage: string;

    @Input()
    logoImageNavLink: string;

    @Input()
    options: SelectOption[];

    @Input()
    selectedOption: string;

    @Input()
    optionTitle = 'Options';

    @Output()
    onOptionSelected: EventEmitter<string> = new EventEmitter<string>();

    activeMenuItem: NbMenuItem;

    subscription: Subscription;

    constructor(private router: Router, private menuService: NbMenuService, private logger: NGXLogger) {
        this.subscription = this.menuService.onItemClick()
            .pipe(map(itemBag => itemBag.item))
            .subscribe(item => {
                this.setActiveMenuItem(item.link);
            });
    }

    ngOnInit(): void {
        this.setActiveMenuItem(this.router.url);
    }

    ngOnDestroy(): void {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    menuItemClicked(menuItem: NbMenuItem): void {
        this.setActiveMenuItem(menuItem.link);
        this.router.navigateByUrl(menuItem.link).then().catch((error) => {
            this.logger.error(`Failed to navigate to ${menuItem.link}`, error);
        });
    }

    navigateLogo(): void {
        if (this.logoImageNavLink) {
            this.setActiveMenuItem(this.logoImageNavLink);
            this.router.navigateByUrl(this.logoImageNavLink).then().catch((error) => {
                this.logger.error(`Failed to navigate to ${this.logoImageNavLink}`, error);
            });
        }
    }

    setActiveMenuItem(link: string): void {
        this.activeMenuItem = this.navMenu?.find(item => item.link === link || item.children?.some(childItem => childItem.link === link));
    }

    onSelectChange(event: any) {
        this.onOptionSelected.emit(event);
    }
}
