import { Pipe, PipeTransform } from '@angular/core';
import { Converter } from 'showdown';

@Pipe({ name: 'markdownFormatter' })
export class MarkdownFormatterPipe implements PipeTransform {

    constructor() {
    }

    transform(value: string): string {
        const converter = new Converter();
        return converter.makeHtml(value);
    }
}
