import { Pipe, PipeTransform } from '@angular/core';
import { TimeZoneOffsetUtil } from '../../@core/utils/time-zone-offset.util';

@Pipe({ name: 'timeZoneOffsetMs' })
export class TimeZoneOffsetMsPipe implements PipeTransform {
    transform(value: number): string {
        const offset = TimeZoneOffsetUtil.fromMilliseconds(value);
        return TimeZoneOffsetUtil.format(offset);
    }
}
