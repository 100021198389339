import { IBusinessUnit } from '../business-unit.interface';
import { Moment } from 'moment';

export interface IDeployment {
    deploymentId: string;
    version: string;
    deploymentName: string;
    template?: IDeploymentTemplate;
    templateId: string;
    description?: string;
    color: string;
    activationTime: Moment;
    deploymentStatusCode: string;
    currentStepIndex?: number;
    steps?: IDeploymentStep[];
}

export interface IDeploymentStep {
    expectedStartTime: Moment;
    actualStartTime?: Moment;
    expectedEndTime: Moment;
    actualEndTime?: Moment;
    manualAdvancement: boolean;
}

export interface IDeploymentTemplate {
    templateId: string;
    templateName: string;
    archived: boolean;
    hidden?: boolean;
    steps: IDeploymentTemplateStep[];
}

export interface IDeploymentTemplateStep {
    templateId: string;
    stepIndex: number;
    durationInDays: number;
    manuallySequenced: boolean;
    pauseOnError: boolean;
    allowOverrideOnPause: boolean;
    forceInstall: boolean;
    storeGroups: IInstallationGroup[];
}

export namespace DeploymentStatusCode {
    export const ACTIVE = 'ACTIVE';
    export const DONE = 'DONE';
    export const CANCELLED = 'CANCELLED';
    export const PAUSED = 'PAUSED';
    export const DELAYED = 'DELAYED';
    export const PENDING = 'PENDING';
    export const UNKNOWN = 'UNKNOWN';
}

export namespace BusinessUnitInstallStatusCode {
    export const SUCCESS = 'SUCCESS';
    export const INSTALLING = 'INSTALLING';
    export const PENDING = 'PENDING';
    export const CANCELLED = 'CANCELLED';
    export const ERROR = 'ERROR';
    export const INSTALLING_WITH_ERROR = 'INSTALLING_WITH_ERROR';
    export const UNKNOWN = 'UNKNOWN';
}

export interface IDeploymentStatistics {
    deployment: IDeployment;
    numberOfStores: number;
    numberOfDevices?: number;
    errors?: IDeploymentError[];
}

export interface IVersionStatistics {
    version: string;
    numberOfStores: number;
    numberOfDevices?: number;
    errors?: IDeploymentError[];
}

export interface IVersionedBusinessUnit {
    businessUnit: IBusinessUnit;
    installations: IInstallation[];
}

export interface IDeploymentError {
    label: string;
    unitAffected: IBusinessUnit;
    installationsAffected?: IInstallation[];
}

export interface IInstallation {
    businessUnitId: string;
    installationId: string;
    version: string;
    packageName: string;
    installTime: Moment;
    successfulInstall: boolean;
    errorMessage: string;
}

export interface IBusinessUnitStatus {
    unit: IBusinessUnit;
    status: string;
    devicesSuccessfullyInstalled: number;
    devicesNotSuccessfullyInstalled?: number;
    devicesInError: number;
}

export interface IPackage {
    packageName: string;
    packageLabel: string;
}

export interface IInstallationGroup {
    groupId: string;
    groupName: string;
    members: IBusinessUnit[];
}

export interface IVersionColorMapping {
    version: string;
    color: string;
}
