import { IBusinessUnit } from '../interfaces/business-unit.interface';

export namespace BusinessUnitActions {

    export class AllBusinessUnitGroups {
        static readonly type = '[Business Unit] All Business Unit Groups';

        constructor(public forceReload?: boolean) {
        }
    }

    export class AllBusinessUnits {
        static readonly type = '[Business Unit] All Business Units';

        constructor(public forceReload?: boolean) {
        }
    }

    export class SaveBusinessUnit {
        static readonly type = '[Business Unit] Save Business Unit';

        constructor(public businessUnit: IBusinessUnit, public saveAddresses?: boolean) {
        }
    }

    export class DeleteBusinessUnit {
        static readonly type = '[Business Unit] Delete Business Unit';

        constructor(public businessUnitId: string) {
        }
    }

    export class AllCountries {
        static readonly type = '[Business Unit] All Countries';
    }

    export class AllBrands {
        static readonly type = '[Business Unit] All Brands';

        constructor(public forceReload?: boolean) {
        }
    }

    export class SearchBusinessUnitGroups {
        static readonly type = '[Business Unit] Search Business Unit Groups';

        constructor(public searchString: string) {
        }
    }

    export class ClearSearch {
        static readonly type = '[Business Unit] Clear Search';
    }
}
