import { APP_INITIALIZER, ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserService } from './users.service';
import { PromotionService } from './promotion.service';
import { DashboardService } from './dashboard.service';
import { BusinessUnitService } from './business-unit.service';
import { ContextService } from './context.service';
import { ItemService } from './item.service';
import { NearMissMessageService } from './near-miss-message-service';
import { StagedPromotionBuilderService } from './staged-promotion-builder.service';
import { CampaignService } from './campaign.service';
import { NotificationService } from './notification.service';
import { PromotionOfferService } from './promotion-offer.service';
import { DeploymentsService } from './deployments.service';
import { DeploymentsDataAggregatorService } from './deployments-data-aggregator.service';
import { ElectronicJournalService } from './electronic-journal.service';
import { IconPackService } from './icon-pack.service';
import { ConfigManagementService } from './config-management.service';

const SERVICES = [
    BusinessUnitService,
    CampaignService,
    ContextService,
    DashboardService,
    ElectronicJournalService,
    IconPackService,
    ItemService,
    NearMissMessageService,
    NotificationService,
    PromotionOfferService,
    PromotionService,
    StagedPromotionBuilderService,
    UserService,
    DeploymentsService,
    DeploymentsDataAggregatorService,
    ConfigManagementService,
    {
        provide: APP_INITIALIZER,
        deps: [IconPackService],
        useFactory: (iconPackService: IconPackService) => () => iconPackService.register(),
        multi: true
    }
];

@NgModule({
    imports: [
        CommonModule,
    ],
    providers: [
        ...SERVICES
    ],
})
export class ServicesModule {
    static forRoot(): ModuleWithProviders<ServicesModule> {
        return {
            ngModule: ServicesModule,
            providers: [
                ...SERVICES,
            ],
        };
    }
}
