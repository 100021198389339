import { Pipe, PipeTransform } from '@angular/core';
import { NestedKeyOf } from 'src/app/@core/interfaces/types';
import { FilterUtil, MatchType } from '../../@core/utils/filter.util';

@Pipe({ name: 'filter' })
export class FilterPipe implements PipeTransform {
    transform<T>(values: T[], filterValue: string, matchType: MatchType, paths?: NestedKeyOf<T>[]): T[] {
        return FilterUtil.filter(values, filterValue, matchType, paths);
    }
}
