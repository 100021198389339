import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
    GetAllNearMissMessagesResponse,
    INearMissMessage,
    NearMissMessageActionResponse,
} from '../interfaces/near-miss-message.interface';

@Injectable()
export class NearMissMessageService {
    readonly nearMissMessageServicePrefix = 'app/nearMissMessages';

    constructor(private http: HttpClient) {
    }

    getAllNearMissMessages(): Observable<GetAllNearMissMessagesResponse> {
        return this.http.get<GetAllNearMissMessagesResponse>(`${this.nearMissMessageServicePrefix}/`);
    }

    saveNearMissMessage(nearMissMessage: INearMissMessage): Observable<NearMissMessageActionResponse> {
        return this.http.put<any>(`${this.nearMissMessageServicePrefix}/`, { nearMissMessage });
    }

    deleteNearMissMessage(id: string): Observable<NearMissMessageActionResponse> {
        return this.http.delete<any>(`${this.nearMissMessageServicePrefix}/${id}`);
    }
}
