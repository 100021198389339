export class NumberUtil {
    static isNumber(value: any): boolean {
        // The `isNaN` function considers `null` as a number, which is annoying, so we handle that case here
        return value !== null && !window.isNaN(value);
    }

    static isWholeNumber(value: any): boolean {
        return value % 1 === 0;
    }
}
