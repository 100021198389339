import { Pipe, PipeTransform } from '@angular/core';

/**
 * Removes revision number from promotionId, if present
 * @example 'CAMP-12-3' -> 'CAMP-12'
 * @example 'CAMP-12' -> 'CAMP-12'
 * @example undefined -> ''
 */
@Pipe({
    name: 'promotionId',
})
export class PromotionIdPipe implements PipeTransform {
    transform(promotionId: string): string {
        return promotionId?.split('-').slice(0, 2).join('-') ?? '';
    }
}
