import { Action, Selector, State, StateContext, StateToken, Store } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { ConfigManagementService } from '../services/config-management.service';
import {
    IConfig,
    IConfigEditGroup,
    IConfigMeta,
    IConfigMetaGroup,
    IDeviceConfiguration,
    IPaymentTerminalConfiguration,
    IPrinterConfiguration
} from '../interfaces/config-management/config-management.interface';
import { forkJoin, Observable, of } from 'rxjs';
import { ConfigManagementActions } from './config-management.actions';
import {
    GetConfigEditGroupsResponse,
    GetConfigMetadataResponse
} from '../interfaces/config-management/config-management-api.interface';
import { catchError, map, tap } from 'rxjs/operators';
import { NgxsForm } from '../interfaces/ngxs-form.interface';
import { NGXLogger } from 'ngx-logger';
import { IBusinessUnit } from '../interfaces/business-unit.interface';
import { ICountry } from '../interfaces/country.interface';
import { IState } from '../interfaces/state.interface';
import { ObjectUtil } from '../utils/object.util';
import { ConfigUtil } from '../utils/config.util';
import { ResetForm } from '@ngxs/form-plugin';

export interface TabFormModel {
    configs: IConfig[];
}

export interface ConfigSearchFilter {
    search?: string;
    tags?: Record<string, string>;
}

export class ConfigManagementStateModel {
    currentSession: IConfigEditGroup;
    basicConfigsForm: NgxsForm<TabFormModel>;
    basicConfigsFormPristineValues: Record<string, any>;
    receiptConfigsForm: NgxsForm<TabFormModel>;
    pendingSessions: IConfigEditGroup[];
    historicalSessions: IConfigEditGroup[];
    basicConfigDefinitions: IConfigMetaGroup[];
    basicConfigSearchFilter: ConfigSearchFilter;
    basicConfigSearchResults: IConfigMetaGroup[];
    deviceConfigDefinitions: IConfigMetaGroup[];
    receiptConfigDefinitions: IConfigMetaGroup[];
    availableTagTypes: string[]; // just the tag names available
    deviceConfigurations: IDeviceConfiguration[];
    paymentTerminalConfigurations: IPaymentTerminalConfiguration[];
    printerConfigurations: IPrinterConfiguration[];
    printerTypes: string[];
    savedTags: Record<string, string>; // saved tags values
    appIds: string[];
    deviceAppIds: string[];
    businessUnits: IBusinessUnit[];
    countries: ICountry[];
    states: IState[];
    devices: any[];
    deviceTypes: string[];
    configListValues: Record<string, any[]>;
    uniqueDeviceHardwareId: string;
}

const CONFIG_MGMT_TOKEN = new StateToken<ConfigManagementStateModel>('configManagement');
export const CONFIG_MGMT_BASIC_TAB_FORM_PATH = `${CONFIG_MGMT_TOKEN.getName()}.basicConfigsForm`;
export const CONFIG_MGMT_RECEIPT_TAB_FORM_PATH = `${CONFIG_MGMT_TOKEN.getName()}.receiptConfigsForm`;

@State<ConfigManagementStateModel>({
    name: CONFIG_MGMT_TOKEN,
    defaults: {
        currentSession: undefined,
        basicConfigsForm: {
            model: {
                configs: []
            },
            dirty: false,
            status: 'VALID',
            errors: undefined
        },
        basicConfigsFormPristineValues: {},
        receiptConfigsForm: {
            model: {
                configs: []
            },
            dirty: false,
            status: 'VALID',
            errors: undefined
        },
        pendingSessions: [],
        historicalSessions: [],
        basicConfigDefinitions: [],
        basicConfigSearchFilter: {},
        basicConfigSearchResults: [],
        deviceConfigDefinitions: [],
        receiptConfigDefinitions: [],
        availableTagTypes: [],
        deviceConfigurations: [],
        printerConfigurations: [],
        paymentTerminalConfigurations: [],
        printerTypes: [],
        savedTags: {},
        appIds: [],
        deviceAppIds: [],
        businessUnits: [],
        countries: [],
        states: [],
        devices: [],
        deviceTypes: [],
        configListValues: {},
        uniqueDeviceHardwareId: undefined
    }
})
@Injectable()
export class ConfigManagementState {

    constructor(private configManagementService: ConfigManagementService, private logger: NGXLogger, private store: Store) {
    }

    private static changedConfigs(state: ConfigManagementStateModel,
                                  formConfigs: IConfig[],
                                  changedConfigArray: { [key: string]: IConfig }): void {
        const configMetas = this.configMetasByName(state);

        formConfigs
            ?.filter(formConfig => !!formConfig.configName)
            .forEach(formConfig => {
                const matchingConfigMeta = configMetas[formConfig.configName];
                const matchingEditConfigValue = ConfigUtil.getValueOrDefault(state.basicConfigsFormPristineValues[formConfig.configName], matchingConfigMeta);

                if (!ObjectUtil.isEqual(formConfig.configValue, matchingEditConfigValue)) {
                    changedConfigArray[formConfig.configName] = formConfig;
                }
            });
    }

    @Selector()
    static currentSession(state: ConfigManagementStateModel): IConfigEditGroup {
        return state.currentSession;
    }

    @Selector()
    static currentSessionBasicConfigs(state: ConfigManagementStateModel): IConfig[] {
        return state.basicConfigsForm?.model?.configs;
    }

    @Selector()
    static currentSessionReceiptConfigs(state: ConfigManagementStateModel): IConfig[] {
        return state.receiptConfigsForm?.model?.configs?.filter(config => !!config.configName);
    }

    @Selector()
    static currentSessionConfigsByName(state: ConfigManagementStateModel): Record<string, IConfig> {
        return ConfigUtil.mapConfigsByName(state.currentSession?.configs);
    }

    @Selector()
    static currentSessionConfigChanges(state: ConfigManagementStateModel): Record<string, IConfig> {
        const changedConfigs: { [key: string]: IConfig } = {};
        this.changedConfigs(state, state.basicConfigsForm?.model?.configs, changedConfigs);
        this.changedConfigs(state, state.receiptConfigsForm?.model?.configs, changedConfigs);
        return changedConfigs;
    }

    @Selector()
    static currentSessionConfigChangesCount(state: ConfigManagementStateModel): number {
        const changedConfigs = this.currentSessionConfigChanges(state);
        return Object.keys(changedConfigs).length;
    }

    @Selector()
    static currentSessionConfigGroupChangesCount(state: ConfigManagementStateModel): Record<string, number> {
        const configMetas = this.configMetasByName(state);
        const changedConfigs = this.currentSessionConfigChanges(state);
        const changesByConfigGroup = {};

        Object.keys(changedConfigs).forEach(configName => {
            const configMetaGroupId = configMetas[configName].configGroupId;

            if (!changesByConfigGroup[configMetaGroupId]) {
                changesByConfigGroup[configMetaGroupId] = 1;
            } else {
                changesByConfigGroup[configMetaGroupId] += 1;
            }
        });

        return changesByConfigGroup;
    }

    @Selector()
    static configListValues(state: ConfigManagementStateModel): Record<string, any[]> {
        return state.configListValues;
    }

    @Selector()
    static pendingSessions(state: ConfigManagementStateModel): IConfigEditGroup[] {
        return state.pendingSessions;
    }

    @Selector()
    static historicalSessions(state: ConfigManagementStateModel): IConfigEditGroup[] {
        return state.historicalSessions;
    }

    @Selector()
    static basicConfigDefinitions(state: ConfigManagementStateModel): IConfigMetaGroup[] {
        return state.basicConfigDefinitions;
    }

    @Selector()
    static receiptConfigDefinitions(state: ConfigManagementStateModel): IConfigMetaGroup[] {
        return state.receiptConfigDefinitions;
    }

    @Selector()
    static configMetasByName(state: ConfigManagementStateModel): Record<string, IConfigMeta> {
        const configMetasByName = {};

        [...state.basicConfigDefinitions, ...state.receiptConfigDefinitions]?.forEach(basicConfig => {
            basicConfig.configMetas.forEach(configMeta => {
                configMetasByName[configMeta.configName] = configMeta;
            });
        });

        return configMetasByName;
    }

    @Selector()
    static basicConfigSearchFilter(state: ConfigManagementStateModel): ConfigSearchFilter {
        return state.basicConfigSearchFilter;
    }

    @Selector()
    static hasBasicConfigSearchFilter(state: ConfigManagementStateModel): boolean {
        return !!state.basicConfigSearchFilter?.search || this.hasBasicConfigSearchFilterTags(state);
    }

    @Selector()
    static hasBasicConfigSearchFilterTags(state: ConfigManagementStateModel): boolean {
        return !ObjectUtil.isValueEmpty(state.basicConfigSearchFilter?.tags);
    }

    @Selector()
    static basicConfigSearchResults(state: ConfigManagementStateModel): IConfigMetaGroup[] {
        return state.basicConfigSearchResults;
    }

    @Selector()
    static uniqueDeviceHardwareId(state: ConfigManagementStateModel): string {
        return state.uniqueDeviceHardwareId;
    }

    @Selector()
    static deviceConfigurations(state: ConfigManagementStateModel): IDeviceConfiguration[] {
        return state.deviceConfigurations;
    }

    @Selector()
    static paymentTerminalConfigurations(state: ConfigManagementStateModel): IPaymentTerminalConfiguration[] {
        return state.paymentTerminalConfigurations;
    }

    @Selector()
    static printerConfigurations(state: ConfigManagementStateModel): IPrinterConfiguration[] {
        return state.printerConfigurations;
    }

    @Selector()
    static availableTagTypes(state: ConfigManagementStateModel): string[] {
        return state.availableTagTypes;
    }

    @Selector()
    static availablePrinterTypes(state: ConfigManagementStateModel): string[] {
        return state.printerTypes;
    }

    @Selector()
    static savedTagValues(state: ConfigManagementStateModel): Record<string, string> {
        return state.savedTags;
    }

    @Selector()
    static appIds(state: ConfigManagementStateModel): string[] {
        return state.appIds;
    }

    @Selector()
    static deviceAppIds(state: ConfigManagementStateModel): string[] {
        return state.deviceAppIds;
    }

    @Selector()
    static businessUnits(state: ConfigManagementStateModel): IBusinessUnit[] {
        return state.businessUnits;
    }

    @Selector()
    static countries(state: ConfigManagementStateModel): ICountry[] {
        return state.countries;
    }

    @Selector()
    static states(state: ConfigManagementStateModel): IState[] {
        return state.states;
    }

    @Selector()
    static devices(state: ConfigManagementStateModel): any[] {
        return state.devices;
    }

    @Selector()
    static deviceTypes(state: ConfigManagementStateModel): string[] {
        return state.deviceTypes;
    }

    @Action(ConfigManagementActions.LoadConfigMetadata)
    loadConfigMetadata(ctx: StateContext<ConfigManagementStateModel>, action: ConfigManagementActions.LoadConfigMetadata): Observable<GetConfigMetadataResponse> {
        this.logger.log('Loading config metadata...');
        return this.configManagementService.getConfigMetadata(action.includeConfiguration).pipe(
            tap(result => {
                ctx.patchState({
                    basicConfigDefinitions: result.basicConfigGroups,
                    deviceConfigDefinitions: result.deviceConfigGroups,
                    receiptConfigDefinitions: result.receiptConfigGroups
                });
            }));
    }

    @Action(ConfigManagementActions.GenerateUniqueDeviceHardwareId)
    generateUniqueDeviceHardwareId(ctx: StateContext<ConfigManagementStateModel>): Observable<string> {
        return this.configManagementService.getUUID().pipe(
            tap(response => ctx.patchState({ uniqueDeviceHardwareId: response.uuid }))
        );
    }

    @Action(ConfigManagementActions.LoadDeviceConfigurations)
    loadDeviceConfigurations(ctx: StateContext<ConfigManagementStateModel>, action: ConfigManagementActions.LoadDeviceConfigurations): Observable<IDeviceConfiguration[]> {
        return this.configManagementService.getDeviceConfigurations(action.businessUnitId).pipe(
            tap(result => {
                this.logger.log(result);
                ctx.patchState({
                    deviceConfigurations: result
                });
            }));
    }

    @Action(ConfigManagementActions.SaveDeviceConfiguration)
    saveDeviceConfiguration(ctx: StateContext<ConfigManagementStateModel>, action: ConfigManagementActions.SaveDeviceConfiguration): Observable<void> {
        return this.configManagementService.saveDevices([action.device], [], []).pipe(
            tap(() => {
                ctx.patchState({
                    deviceConfigurations: [...ctx.getState().deviceConfigurations, action.device]
                });
            }));
    }

    @Action(ConfigManagementActions.DeleteDeviceConfiguration)
    deleteDeviceConfiguration(ctx: StateContext<ConfigManagementStateModel>, action: ConfigManagementActions.DeleteDeviceConfiguration): Observable<void> {
        return this.configManagementService.deleteDeviceConfiguration(action.device.deviceId, action.device.deviceName).pipe(
            tap(() => {
                ctx.patchState({
                    deviceConfigurations: [...ctx.getState().deviceConfigurations.filter(e => e.deviceId !== action.device.deviceId)]
                });
            }));
    }

    @Action(ConfigManagementActions.LoadPaymentTerminalConfigurations)
    loadPaymentTerminalConfigurations(ctx: StateContext<ConfigManagementStateModel>, action: ConfigManagementActions.LoadPaymentTerminalConfigurations): Observable<IPaymentTerminalConfiguration[]> {
        return this.configManagementService.getPaymentTerminalConfigurations(action.businessUnitId).pipe(
            tap(result => {
                ctx.patchState({
                    paymentTerminalConfigurations: result
                });
            }));
    }

    @Action(ConfigManagementActions.SavePaymentTerminalConfiguration)
    savePaymentTerminalConfiguration(ctx: StateContext<ConfigManagementStateModel>, action: ConfigManagementActions.SavePaymentTerminalConfiguration): Observable<void> {
        return this.configManagementService.saveDevices([], [], [action.paymentTerminal]).pipe(
            tap(() => {
                ctx.patchState({
                    paymentTerminalConfigurations: [...ctx.getState().paymentTerminalConfigurations, action.paymentTerminal]
                });
            }));
    }

    @Action(ConfigManagementActions.DeletePaymentTerminalConfiguration)
    deletePaymentTerminalConfiguration(ctx: StateContext<ConfigManagementStateModel>, action: ConfigManagementActions.DeletePaymentTerminalConfiguration): Observable<void> {
        return this.configManagementService.deletePaymentTerminalConfiguration(action.paymentTerminal.businessUnitId, action.paymentTerminal.id).pipe(
            tap(() => {
                ctx.patchState({
                    paymentTerminalConfigurations: [...ctx.getState().paymentTerminalConfigurations.filter(e => e.id !== action.paymentTerminal.id)]
                });
            }));
    }

    @Action(ConfigManagementActions.LoadPrinterConfigurations)
    loadPrinterConfigurations(ctx: StateContext<ConfigManagementStateModel>, action: ConfigManagementActions.LoadPrinterConfigurations): Observable<IPrinterConfiguration[]> {
        return this.configManagementService.getPrinterConfigurations(action.businessUnitId).pipe(
            tap(result => {
                ctx.patchState({
                    printerConfigurations: result
                });
            }));
    }

    @Action(ConfigManagementActions.SavePrinterConfiguration)
    savePrinterConfiguration(ctx: StateContext<ConfigManagementStateModel>, action: ConfigManagementActions.SavePrinterConfiguration): Observable<void> {
        return this.configManagementService.saveDevices([], [action.printer], []).pipe(
            tap(() => {
                ctx.patchState({
                    printerConfigurations: [...ctx.getState().printerConfigurations, action.printer]
                });
            }));
    }

    @Action(ConfigManagementActions.DeletePrinterConfiguration)
    deletePrinterConfiguration(ctx: StateContext<ConfigManagementStateModel>, action: ConfigManagementActions.DeletePrinterConfiguration): Observable<void> {
        return this.configManagementService.deletePrinterConfiguration(action.printer.businessUnitId, action.printer.id).pipe(
            tap(() => {
                ctx.patchState({
                    printerConfigurations: [...ctx.getState().printerConfigurations.filter(e => e.id !== action.printer.id)]
                });
            }));
    }

    @Action(ConfigManagementActions.LoadPrinterTypes)
    loadPrinterTypes(ctx: StateContext<ConfigManagementStateModel>): Observable<string[]> {
        return this.configManagementService.getPrinterTypes().pipe(
            tap(result => {
                ctx.patchState({
                    printerTypes: result
                });
            }));
    }

    @Action(ConfigManagementActions.LoadHistoricalSessions)
    loadHistoricalSessions(ctx: StateContext<ConfigManagementStateModel>): Observable<GetConfigEditGroupsResponse> {
        return this.configManagementService.getConfigEditGroups().pipe(
            tap((result: GetConfigEditGroupsResponse) => {
                ctx.patchState({
                    pendingSessions: result.pendingSessions,
                    historicalSessions: result.historicalSessions
                });
            })
        );
    }

    @Action(ConfigManagementActions.StartNewConfigSession)
    startNewConfigSession(ctx: StateContext<ConfigManagementStateModel>, action: ConfigManagementActions.StartNewConfigSession): Observable<IConfigEditGroup> {
        this.store.dispatch(new ResetForm({ path: CONFIG_MGMT_BASIC_TAB_FORM_PATH }));
        this.store.dispatch(new ResetForm({ path: CONFIG_MGMT_RECEIPT_TAB_FORM_PATH }));
        this.store.dispatch(ConfigManagementActions.LoadConfigListValues);

        return this.configManagementService.startNewSession(action.newConfigSession)
            .pipe(
                map(response => response.group),
                tap(configEditGroup => ctx.patchState({
                    basicConfigsFormPristineValues: {},
                    currentSession: configEditGroup
                }))
            );
    }

    @Action(ConfigManagementActions.CancelConfigSession)
    cancelConfigSession(ctx: StateContext<ConfigManagementStateModel>, action: ConfigManagementActions.CancelConfigSession): Observable<any> {
        this.store.dispatch(new ResetForm({ path: CONFIG_MGMT_BASIC_TAB_FORM_PATH }));
        this.store.dispatch(new ResetForm({ path: CONFIG_MGMT_RECEIPT_TAB_FORM_PATH }));
        return this.configManagementService.cancelConfigSession(action.editGroupId)
            .pipe(
                map(response => response.group),
                tap(() => ctx.patchState({ currentSession: undefined }))
            );
    }

    @Action(ConfigManagementActions.SaveConfigSession)
    saveConfigSession(ctx: StateContext<ConfigManagementStateModel>): Observable<IConfigEditGroup> {
        const state = ctx.getState();
        const configChanges = ConfigManagementState.currentSessionConfigChanges(state);
        const updatedSession: IConfigEditGroup = {
            ...state.currentSession,
            configs: Object.keys(configChanges).map(configName => configChanges[configName])
        };

        return this.configManagementService.saveConfigEditGroup(updatedSession)
            .pipe(
                map(response => response.group),
                tap(() => ctx.patchState({ currentSession: undefined }))
            );
    }

    @Action(ConfigManagementActions.RemoveConfigSession)
    removeConfigSession(ctx: StateContext<ConfigManagementStateModel>, action: ConfigManagementActions.RemoveConfigSession): Observable<IConfigEditGroup> {
        const state = ctx.getState();

        return this.configManagementService.removeConfigEditGroup(action.editGroupId)
            .pipe(
                tap(() => {
                    ctx.patchState({
                        pendingSessions: [...state.pendingSessions].filter(session => session.editGroupId !== action.editGroupId)
                    });
                })
            );
    }

    @Action(ConfigManagementActions.ResumeConfigSession)
    resumeConfigSession(ctx: StateContext<ConfigManagementStateModel>, action: ConfigManagementActions.ResumeConfigSession): Observable<IConfigEditGroup> {
        const state = ctx.getState();
        const sessionToResume = state.pendingSessions
            .concat(state.historicalSessions)
            .find(session => session.editGroupId === action.editGroupId);

        const pristineValues = {};

        sessionToResume.configs.forEach(config => pristineValues[config.configName] = config.configValue);

        this.store.dispatch(new ResetForm({ path: CONFIG_MGMT_BASIC_TAB_FORM_PATH }));
        this.store.dispatch(new ResetForm({ path: CONFIG_MGMT_RECEIPT_TAB_FORM_PATH }));
        this.store.dispatch(ConfigManagementActions.LoadConfigListValues);

        ctx.patchState({
            basicConfigsFormPristineValues: pristineValues,
            currentSession: sessionToResume
        });

        return of(sessionToResume);
    }

    @Action(ConfigManagementActions.UpdateBasicConfigSearchFilter)
    updateBasicConfigSearchFilter(ctx: StateContext<ConfigManagementStateModel>, action: ConfigManagementActions.UpdateBasicConfigSearchFilter) {
        const state = ctx.getState();

        ctx.patchState({
            basicConfigSearchFilter: {
                ...state.basicConfigSearchFilter,
                search: action.search
            }
        });
    }

    @Action(ConfigManagementActions.UpdateBasicConfigTagsFilter)
    updateBasicConfigTagsFilter(ctx: StateContext<ConfigManagementStateModel>, action: ConfigManagementActions.UpdateBasicConfigTagsFilter) {
        const state = ctx.getState();

        ctx.patchState({
            basicConfigSearchFilter: {
                ...state.basicConfigSearchFilter,
                tags: action.tags
            }
        });
    }

    @Action(ConfigManagementActions.SearchBasicConfig)
    searchBasicConfig(ctx: StateContext<ConfigManagementStateModel>) {
        const matchingConfigMetaGroups: IConfigMetaGroup[] = [];
        const state = ctx.getState();
        const searchFilter = state.basicConfigSearchFilter;
        const search = state.basicConfigSearchFilter?.search?.toLowerCase();

        state.basicConfigDefinitions.forEach(configMetaGroup => {
            const matchingConfigMetas = [];

            configMetaGroup.configMetas.filter(configMeta => {
                let hasMatchingTags = true;
                let hasMatchingSearch = true;

                // Filter out config metas that don't match the search tags if there's no current edit session
                if (!state.currentSession && !ObjectUtil.isValueEmpty(searchFilter?.tags)) {
                    hasMatchingTags = configMeta.configs.some(config => ConfigUtil.hasTags(config, searchFilter?.tags));
                }

                // Search the configuration hierarchy for properties matching the search. This is allowed when viewing
                // and editing configuration sessions
                if (hasMatchingTags && !ObjectUtil.isValueEmpty(search)) {
                    // Start searching the meta group
                    hasMatchingSearch = configMetaGroup.displayName?.toLowerCase().includes(search) ||
                        configMetaGroup.groupDescription?.toLowerCase().includes(search);

                    // Next search the config meta
                    if (!hasMatchingSearch) {
                        hasMatchingSearch =  configMeta.displayName?.toLowerCase().includes(search) ||
                            configMeta.configDescription?.toLowerCase().includes(search);
                    }
                }

                if (hasMatchingTags && hasMatchingSearch) {
                    matchingConfigMetas.push(configMeta);
                }
            });

            if (matchingConfigMetas.length > 0) {
                matchingConfigMetaGroups.push({
                    ...configMetaGroup,
                    configMetas: matchingConfigMetas
                });
            }
        });

        ctx.patchState({
            basicConfigSearchResults: matchingConfigMetaGroups
        });
    }

    @Action(ConfigManagementActions.UpdateSessionConfigs)
    updateSessionConfigs(ctx: StateContext<ConfigManagementStateModel>, action: ConfigManagementActions.UpdateSessionConfigs): void {
        if (ctx.getState().currentSession) {
            ctx.patchState({
                currentSession: {
                    ...ctx.getState().currentSession,
                    configs: action.configs
                }
            });
        }
    }

    @Action(ConfigManagementActions.LoadTags)
    // TODO: REMOVE the eslint-disable once function is implemented
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    loadTags(ctx: StateContext<ConfigManagementStateModel>, action: ConfigManagementActions.LoadTags): Observable<any> {
        this.logger.log('Loading Tags...');
        return of();
        // TODO Load tag types / saved values
    }

    @Action(ConfigManagementActions.AddNewTagValue)
    // TODO: REMOVE the eslint-disable once function is implemented
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    addNewTagValue(ctx: StateContext<ConfigManagementStateModel>, action: ConfigManagementActions.AddNewTagValue): Observable<any> {
        return of();
        // TODO
    }

    @Action(ConfigManagementActions.LoadAppIds)
    loadAppIds(ctx: StateContext<ConfigManagementStateModel>): Observable<string[]> {
        return this.configManagementService.getAppIds().pipe(
            tap(result => {
                ctx.patchState({
                    appIds: result
                });
            }));
    }

    @Action(ConfigManagementActions.LoadDeviceAppIds)
    loadDeviceAppIds(ctx: StateContext<ConfigManagementStateModel>): Observable<string[]> {
        return this.configManagementService.getDeviceAppIds().pipe(
            tap(result => {
                ctx.patchState({
                    deviceAppIds: result
                });
            }));
    }

    @Action(ConfigManagementActions.LoadBusinessUnits)
    loadBusinessUnits(ctx: StateContext<ConfigManagementStateModel>): Observable<IBusinessUnit[]> {
        return this.configManagementService.getBusinessUnits().pipe(
            tap(businessUnits => ctx.patchState({ businessUnits }))
        );
    }

    @Action(ConfigManagementActions.LoadCountries)
    loadCountries(ctx: StateContext<ConfigManagementStateModel>): Observable<ICountry[]> {
        return this.configManagementService.getCountries().pipe(
            tap(countries => ctx.patchState({ countries }))
        );
    }

    @Action(ConfigManagementActions.LoadStates)
    loadStates(ctx: StateContext<ConfigManagementStateModel>): Observable<IState[]> {
        return this.configManagementService.getStates().pipe(
            tap(states => ctx.patchState({ states }))
        );
    }

    @Action(ConfigManagementActions.LoadDevices)
    loadDevices(ctx: StateContext<ConfigManagementStateModel>): Observable<any[]> {
        return this.configManagementService.getDevices().pipe(
            tap(devices => ctx.patchState({ devices }))
        );
    }

    @Action(ConfigManagementActions.LoadDeviceTypes)
    loadDeviceTypes(ctx: StateContext<ConfigManagementStateModel>): Observable<string[]> {
        return this.configManagementService.getDeviceTypes().pipe(
            tap(deviceTypes => ctx.patchState({ deviceTypes }))
        );
    }

    @Action(ConfigManagementActions.LoadConfigListValues)
    loadConfigListValues(ctx: StateContext<ConfigManagementStateModel>): Observable<Record<string, any[]>> {
        return forkJoin({
            'openpos.ops.till.tillType': this.configManagementService.getTillTypes(),
            'openpos.general.businessUnitLocale': this.configManagementService.getLocales()
            // TODO: Fill in other configuration list values here
        }).pipe(
            tap(configListValues => ctx.patchState({ configListValues }))
        );
    }
}
