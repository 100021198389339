import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxsFormPluginModule } from '@ngxs/form-plugin';
import { ThemeModule } from '../@theme/theme.module';
import { InputComponent } from './components/input/input.component';
import { SelectComponent } from './components/select/select.component';
import { CheckboxComponent } from './components/checkbox/checkbox.component';
import { DateTimepickerComponent } from './components/date-timepicker/date-timepicker.component';
import { FormsService } from './forms.service';
import { ColorSelectComponent } from './components/color-select/color-select.component';
import { MultiCheckboxComponent } from './components/multi-checkbox/multi-checkbox.component';
import { RadioGroupComponent } from './components/radio-group/radio-group.component';
import { FileUploadComponent } from './components/file-upload/file-upload.component';
import { DateTimePickerDirective } from './directives/date-time-picker/date-time-picker.directive';

const components = [
    CheckboxComponent,
    ColorSelectComponent,
    DateTimepickerComponent,
    DateTimePickerDirective,
    FileUploadComponent,
    InputComponent,
    MultiCheckboxComponent,
    RadioGroupComponent,
    SelectComponent,
];
const modules = [
    FormsModule,
    NgxsFormPluginModule,
    ReactiveFormsModule,
];
const services = [
    FormsService
];

@NgModule({
    declarations: [...components],
    imports: [...modules, ThemeModule],
    exports: [...components, ...modules],
    providers: [...services]
})
export class JMCCFormsModule { }
