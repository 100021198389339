import { Pipe, PipeTransform } from '@angular/core';
import { ConfigMetaTypeCode } from '../../@core/interfaces/config-management/config-management.interface';
import { ConfigUtil } from '../../@core/utils/config.util';

@Pipe({ name: 'mapConfigValue' })
export class MapConfigValuePipe implements PipeTransform {
    transform(value: any, type?: ConfigMetaTypeCode): any {
        return ConfigUtil.mapValue(value, type);
    }
}
