import { Component, Input, TemplateRef, ViewChild } from '@angular/core';

@Component({
    selector: 'jmcc-step',
    templateUrl: './step.component.html',
})
export class StepComponent {

    @Input()
    label: string;

    @Input()
    subLabel: string;

    @Input()
    completed: boolean;

    @Input()
    cancelled: boolean;

    @Input()
    loading = false;

    @Input()
    status: 'warning' | 'success' | 'danger' | 'info' = 'info';

    @Input()
    visited: boolean;

    @Input()
    connectorLabelTop: string;

    @Input()
    connectorLabelBottom: string;

    @ViewChild(TemplateRef, { static: true }) content: TemplateRef<any>;

    isCompleted(): boolean {
        return (this.completed || this.visited) && !this.loading;
    }
}
