import * as _ from 'lodash';
import * as moment from 'moment';
import { isMoment } from 'moment';

export interface DirtyProperty {
    name: string;
    current: any;
    previous: any;
}

export type DirtyPropertySet<T> = {
    [property in keyof T]: DirtyProperty;
};

export class ObjectUtil {
    static getNonEmptyValueKeys(obj: object): string[] {
        return Object.keys(obj || {})
            .filter(key => !this.isValueEmpty(obj[key]));
    }

    static getEmptyValueKeys(obj: object): string[] {
        return Object.keys(obj || {})
            .filter(key => this.isValueEmpty(obj[key]));
    }

    static isValueEmpty(value: any): boolean {
        if (value?.constructor === Array || typeof value === 'string') {
            return value.length === 0;
        }

        if (value?.constructor === Object) {
            const hasNonEmptyValues = Object.keys(value).some(key => !this.isValueEmpty(value[key]));
            // We unfortunately end up with a double-negative here because it's faster to sort circuit on
            // the first non-empty instead of checking is every value is empty
            return !hasNonEmptyValues;
        }

        return value === undefined || value === null;
    }

    static isEqual(value1: any, value2: any): boolean {
        const isValue1Empty = this.isValueEmpty(value1);
        const isValue2Empty = this.isValueEmpty(value2);

        if (isValue1Empty && isValue2Empty) {
            return true;
        }

        // Special case for Moment
        if (isMoment(value1) || isMoment(value2)) {
            return moment(value1).isSame(moment(value2));
        }

        // Special case for Date
        if (value1 instanceof Date || value2 instanceof Date) {
            value1 = value1 instanceof Date ? value1 : new Date(value1);
            value2 = value2 instanceof Date ? value2 : new Date(value2);

            return value1.getTime() === value2.getTime();
        }

        return _.isEqual(value1, value2);
    }

    static getDirtyProperties(current: object, previous: object): Record<string, DirtyProperty> {
        const differences: Record<string, DirtyProperty> = {};

        Object.keys(current || {}).forEach(key => {
            if (!this.isEqual(current?.[key], previous?.[key])) {
                differences[key] = {
                    name: key,
                    current: current?.[key],
                    previous: previous?.[key]
                };
            }
        });

        return differences;
    }
}
