import { Injectable } from '@angular/core';
import { Action, createSelector, Selector, State, StateContext, StateToken, Store } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import { ICampaignDetails } from '../interfaces/promotion/campaign-details.interface';
import { IStagedPromotion, PromoStatusCode } from '../interfaces/promotion/staged-promotion.interface';
import { PromotionService } from '../services/promotion.service';
import { PromotionDashboardActions } from './promotion-dashboard.actions';
import { cloneDeep } from 'lodash';
import { Observable, throwError } from 'rxjs';
import { CampaignStateModel } from './campaign.state';
import { IGenericServerResponse } from '../interfaces/generic-server-response';
import { AuthorizedState, AuthorizedStateModel } from './authorized.state';
import { PromotionWorkflowState } from './promotion-workflow.state';
import { IUser } from '../interfaces/user.interface';
import { NgxsForm } from '../interfaces/ngxs-form.interface';
import { PromotionFiltersFormModel } from '../interfaces/promotion/promotion-filters-form.interface';
import * as moment from 'moment';
import { Moment } from 'moment';
import { ObjectTypeCode } from '../interfaces/promotion/object-type-code';
import { CommentsActions } from './comments.actions';
import { PromotionWorkflowActions } from './promotion-workflow.actions';
import { AdvancedSearchRule, AdvancedSearchParameters } from '../interfaces/promotion/advanced-search-rule.interface';

export class PromotionDashboardStateModel {
    campaigns: ICampaignDetails[];
    stagedPromotions: IStagedPromotion[];
    isoCurrencyCode: string;
    filtersForm: NgxsForm<PromotionFiltersFormModel>;
    advancedSearchRules: AdvancedSearchRule[];
    advancedSearchMatchType: string;
    selectedCampaignId?: string;
    isLoadingDashboard: boolean;
}

const PROMOTION_DASHBOARD_STATE_TOKEN = new StateToken<PromotionDashboardStateModel>('promotionDashboard');

@State<PromotionDashboardStateModel>({
    name: PROMOTION_DASHBOARD_STATE_TOKEN,
    defaults: {
        campaigns: [],
        stagedPromotions: [],
        isoCurrencyCode: 'USD',
        filtersForm: {
            model: undefined,
            dirty: false,
            status: '',
            errors: {}
        },
        advancedSearchRules: [],
        advancedSearchMatchType: null,
        selectedCampaignId: null,
        isLoadingDashboard: false,
    }
})
@Injectable()
export class PromotionDashboardState {

    constructor(private promotionService: PromotionService, private store: Store) {
    }

    static promotionsInCampaign(campaignId: string) {
        return createSelector([PromotionDashboardState], (state: PromotionDashboardStateModel): IStagedPromotion[] =>
            state.stagedPromotions?.
                filter(p => p.campaignId === campaignId && this.shouldShowPromotion(state, p)).
                sort((a: IStagedPromotion, b: IStagedPromotion) =>
                    new Date(b.lastUpdateTime).getTime() - new Date(a.lastUpdateTime).getTime()
                ));
    }

    static promotionsWithStatus(status: string) {
        return createSelector([PromotionDashboardState], (state: PromotionDashboardStateModel): IStagedPromotion[] =>
            state.stagedPromotions?.
                filter(p => p.promoStatusCode === status && this.shouldShowPromotion(state, p)).
                sort((a: IStagedPromotion, b: IStagedPromotion) =>
                    new Date(b.lastUpdateTime).getTime() - new Date(a.lastUpdateTime).getTime()
                ));
    }

    private static shouldShowPromotion(state: PromotionDashboardStateModel, promotion: IStagedPromotion): boolean {
        return this.matchesSearchStringFilter(state.filtersForm.model?.searchFilter, promotion)
            && this.matchesUserFilters(state.filtersForm.model?.userFilters, promotion)
            && this.matchesLabelFilters(state.filtersForm.model?.labelFilters, promotion)
            && this.matchesMinStartDateFilter(state.filtersForm.model?.minStartDateFilter, promotion)
            && this.matchesMaxEndDateFilter(state.filtersForm.model?.maxEndDateFilter, promotion);
    }

    private static matchesSearchStringFilter(searchString: string, promotion: IStagedPromotion): boolean {
        if (searchString) {
            return promotion.promotionId?.toLowerCase().includes(searchString)
                || promotion.promotionName?.toLowerCase().includes(searchString)
                || promotion.longDescription?.toLowerCase().includes(searchString);
        }
        return true;
    }

    private static matchesUserFilters(userFilters: IUser[], promotion: IStagedPromotion): boolean {
        if (userFilters?.length) {
            return promotion.collaborators?.some(user => userFilters.some(filter => filter.userId === user.userId));
        }
        return true;
    }

    private static matchesLabelFilters(labelFilters: string[], promotion: IStagedPromotion): boolean {
        if (labelFilters?.length) {
            return promotion.labels?.some(label => labelFilters.some(filter => filter === label.labelId));
        }
        return true;
    }

    private static matchesMinStartDateFilter(startDateFilter: Moment, promotion: IStagedPromotion): boolean {
        if (startDateFilter) {
            const promotionStart = moment(new Date(promotion.effectiveStartTime));
            return promotionStart.isSameOrAfter(startDateFilter.startOf('day'));
        }
        return true;
    }

    private static matchesMaxEndDateFilter(endDateFilter: Moment, promotion: IStagedPromotion): boolean {
        if (endDateFilter) {
            const promotionEnd = moment(new Date(promotion.effectiveEndTime));
            return promotionEnd.isSameOrBefore(endDateFilter.endOf('day'));
        }
        return true;
    }

    private static shouldShowCampaign(state: PromotionDashboardStateModel, campaign: ICampaignDetails): boolean {
        if (this.hasActiveFilter(state)) {
            return state.stagedPromotions?.some(p => campaign?.campaign?.campaignId === p.campaignId && this.shouldShowPromotion(state, p));
        }
        return true;
    }

    @Selector()
    static unassignedPromotions(state: PromotionDashboardStateModel): IStagedPromotion[] {
        return state.stagedPromotions?.filter(p => !p.campaignId && this.shouldShowPromotion(state, p));
    }

    @Selector()
    static isLoadingDashboard(state: PromotionDashboardStateModel): boolean {
        return state.isLoadingDashboard;
    }

    @Selector()
    static activeCampaigns(state: PromotionDashboardStateModel): ICampaignDetails[] {
        return cloneDeep(state.campaigns)
            .filter((c: ICampaignDetails) => this.shouldShowCampaign(state, c))
            .sort((a: ICampaignDetails, b: ICampaignDetails) => {
                if (!a.campaign || !b.campaign) {
                    return 0;
                }
                return new Date(b.campaign.lastUpdateTime).getTime() - new Date(a.campaign.lastUpdateTime).getTime();
            });
    }

    @Selector()
    static currencyCode(state: PromotionDashboardStateModel): string {
        return state.isoCurrencyCode;
    }

    @Selector()
    static totalPromotions(state: PromotionDashboardStateModel): number {
        return state.stagedPromotions ? state.stagedPromotions.filter(p => this.shouldShowPromotion(state, p)).length : 0;
    }

    @Selector()
    static totalRedemptions(state: PromotionDashboardStateModel): number {
        if (state.campaigns) {
            return state.campaigns.reduce((sum: number, campaign: ICampaignDetails) => {
                if (campaign?.totalRedemptions) {
                    return sum + campaign.totalRedemptions;
                }
                return sum;
            }, 0);
        }
        return 0;
    }

    @Selector()
    static totalRevenue(state: PromotionDashboardStateModel): number {
        if (state.campaigns) {
            return state.campaigns.reduce((sum: number, campaign: ICampaignDetails) => {
                if (campaign?.totalRevenue) {
                    return sum + campaign.totalRevenue;
                }
                return sum;
            }, 0);
        }
        return 0;
    }

    @Selector()
    static totalCustomerSavings(state: PromotionDashboardStateModel): number {
        if (state.campaigns) {
            return state.campaigns.reduce((sum: number, campaign: ICampaignDetails) => {
                if (campaign?.customerSavings) {
                    return sum + campaign.customerSavings;
                }
                return sum;
            }, 0);
        }
        return 0;
    }

    @Selector()
    static hasActiveFilter(state: PromotionDashboardStateModel): boolean {
        return !!state.filtersForm.model?.searchFilter
            || !!state.filtersForm.model?.userFilters?.length
            || !!state.filtersForm.model?.labelFilters?.length
            || !!state.filtersForm.model?.minStartDateFilter
            || !!state.filtersForm.model?.maxEndDateFilter;
    }

    @Selector()
    static hasAdvancedSearchRules(state: PromotionDashboardStateModel): boolean {
        return !!state.advancedSearchRules?.length;
    }

    @Selector()
    static advancedSearchParameters(state: PromotionDashboardStateModel): AdvancedSearchParameters {
        return {
            matchType: state.advancedSearchMatchType,
            rules: state.advancedSearchRules,
        };
    }

    @Selector()
    static selectedCampaign(state: PromotionDashboardStateModel): ICampaignDetails {
        return state.selectedCampaignId
            ? state.campaigns.find(c => c.campaign.campaignId === state.selectedCampaignId)
            : null;
    }

    @Selector([PromotionDashboardState, AuthorizedState])
    static promotionsInProgress(state: PromotionDashboardStateModel, authorizedState: AuthorizedStateModel): IStagedPromotion[] {
        const authorizedUser = authorizedState.authorizedUser;
        return state.stagedPromotions.filter(p => this.shouldShowPromotion(state, p)
            && p.collaborators?.some(user => user.userId === authorizedUser?.userId)
            && (p.promoStatusCode === PromoStatusCode.DRAFT || p.promoStatusCode === PromoStatusCode.PENDING_APPROVAL));
    }

    @Selector([PromotionDashboardState, PromotionWorkflowState.promotionIdsToReview])
    static promotionsForReview(state: PromotionDashboardStateModel, promotionIdsToReview: string[]): IStagedPromotion[] {
        return state.stagedPromotions.filter(p => this.shouldShowPromotion(state, p) && promotionIdsToReview?.includes(p.promotionId));
    }

    @Action(PromotionDashboardActions.ResetDashboard)
    resetDashboard(ctx: StateContext<PromotionDashboardStateModel>): Observable<any> {
        ctx.patchState({
            isLoadingDashboard: false
        });
        return this.store.dispatch(new PromotionDashboardActions.GetDashboardData(true));
    }

    @Action(PromotionDashboardActions.GetDashboardData)
    getDashboardData(ctx: StateContext<PromotionDashboardStateModel>, action: PromotionDashboardActions.GetDashboardData): Observable<PromotionDashboardStateModel> {
        if (this.canReloadDashboard(ctx, action)) {
            const advancedSearchRules = action.searchParams ? action.searchParams : ctx.getState().advancedSearchRules;
            const advancedSearchMatchType = action.matchType ? action.matchType : ctx.getState().advancedSearchMatchType;
            ctx.patchState({
                isLoadingDashboard: true
            });
            return this.promotionService.getDashboardData(action.forceReload, advancedSearchRules, advancedSearchMatchType).pipe(
                tap((result: PromotionDashboardStateModel) => {
                    if (result) {
                        if (result.stagedPromotions) {
                            const allPromotionIds = result.stagedPromotions.map(stagedPromotion => stagedPromotion.promotionId);
                            this.store.dispatch(new CommentsActions.GetComments({ objectIds: allPromotionIds, objectType: ObjectTypeCode.PROMOTION }, true));
                            this.store.dispatch(new PromotionWorkflowActions.GetWorkflowItems({ objectIds: allPromotionIds, objectType: ObjectTypeCode.PROMOTION }));
                        }
                        ctx.patchState({
                            campaigns: result.campaigns,
                            stagedPromotions: result.stagedPromotions,
                            isoCurrencyCode: result.isoCurrencyCode,
                            advancedSearchRules,
                            advancedSearchMatchType,
                            isLoadingDashboard: false
                        });
                    } else {
                        ctx.patchState({
                            isLoadingDashboard: false
                        });
                    }
                },
                    (error: any) => {
                        ctx.patchState({
                            isLoadingDashboard: false
                        });
                        return throwError(error);
                    })
            );
        }
    }

    @Action(PromotionDashboardActions.ClearFilters)
    clearFilters(ctx: StateContext<PromotionDashboardStateModel>) {
        ctx.patchState({
            filtersForm: {
                model: {
                    searchFilter: null,
                    userFilters: [],
                    labelFilters: [],
                    minStartDateFilter: null,
                    maxEndDateFilter: null
                },
                dirty: false,
                status: '',
                errors: {}
            },
        });
    }

    @Action(PromotionDashboardActions.ClearSearch)
    clearSearch(ctx: StateContext<PromotionDashboardStateModel>) {
        ctx.patchState({
            advancedSearchRules: [],
            advancedSearchMatchType: null,
        });
        this.store.dispatch(new PromotionDashboardActions.GetDashboardData(true));
    }

    @Action(PromotionDashboardActions.DeletePromotion)
    deletePromotion(ctx: StateContext<CampaignStateModel>, action: PromotionDashboardActions.DeletePromotion): Observable<IGenericServerResponse> {
        return this.promotionService.deletePromotion(action.id).pipe(
            tap((result: IGenericServerResponse) => {
                if (result.success) {
                    this.store.dispatch(new PromotionDashboardActions.GetDashboardData(true));
                } else {
                    throw new Error(result.message);
                }
            })
        );
    }

    @Action(PromotionDashboardActions.SetSelectedCampaignId)
    setSelectedCampaign(ctx: StateContext<PromotionDashboardStateModel>, action: PromotionDashboardActions.SetSelectedCampaignId): void {
        ctx.patchState({ selectedCampaignId: action.campaignId });
    }

    private canReloadDashboard(ctx: StateContext<PromotionDashboardStateModel>, action: PromotionDashboardActions.GetDashboardData): boolean {
        return (!ctx.getState().stagedPromotions?.length || !ctx.getState().campaigns?.length || action.forceReload)
            && (!ctx.getState().isLoadingDashboard);
    }
}
